import React, { useState } from "react";

import { ReactComponent as FavoriteSolid } from "assets/icons/favorite-solid-16.svg";
import { ReactComponent as FavoriteOutline } from "assets/icons/favorite-outline-16.svg";
import CircleButton from "components/CircleButton";

export default function FavoriteButton() {
  const [isActive, setIsActive] = useState<boolean>(false);
  return (
    <CircleButton onClick={() => setIsActive(!isActive)}>
      {isActive ? (
        <FavoriteSolid className="path-current fill-current text-red-500" />
      ) : (
        <FavoriteOutline className="path-current fill-current text-gray-600" />
      )}
    </CircleButton>
  );
}
