import React, { ReactElement } from "react";

import { FONTS } from "assets/theme";
import ModalLayout from "components/common/ModalLayout";
import { ReactComponent as DeliverySVG } from "assets/icons/delivery-fast.svg";

export default function RawErrorPage(): ReactElement {
  return (
    <ModalLayout
      titleElement={
        <span style={{ fontFamily: FONTS, fontWeight: "bold" }}>Error</span>
      }
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "250px"
        }}
      >
        <h1
          style={{ fontFamily: FONTS, fontWeight: "bold", textAlign: "center" }}
        >
          Something went wrong
        </h1>
        <p style={{ color: "#808080" }}>We're working on it!</p>
        <div style={{ margin: "20px" }}>
          <DeliverySVG
            filter="invert(69%) sepia(18%) saturate(0%) hue-rotate(256deg) brightness(97%)
          contrast(89%)"
            title="error"
          />
        </div>
      </div>
    </ModalLayout>
  );
}
