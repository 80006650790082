import React, { useContext } from "react";
import { ReactComponent as LeftIcon } from "assets/icons/arrow-left.svg";
import { ReactComponent as RightIcon } from "assets/icons/arrow-right.svg";
import CarouselContext from "components/SlidesCarousel/Context";
import { getIsFirst, getIsLast } from "components/SlidesCarousel/utils";

const SlidesNav = () => {
  const { dispatch, ...state } = useContext(CarouselContext);
  const isLast = getIsLast(state);
  const isFirst = getIsFirst(state);
  return (
    <div className="flex -mx-1">
      <div className="flex px-2 items-center">{`${state.index + 1} / ${
        state.formSlides.length
      }`}</div>
      <div
        className="p-2 rounded bg-gray-100 mx-1"
        onClick={isFirst ? undefined : () => dispatch({ type: "previous" })}
      >
        <LeftIcon
          className={`ponter path-current fill-current opacity-${
            isFirst ? "50" : "100"
          }`}
        />
      </div>
      <div
        className="p-2 rounded bg-gray-100 mx-1"
        onClick={isLast ? undefined : () => dispatch({ type: "next" })}
      >
        <RightIcon
          className={`ponter path-current fill-current opacity-${
            isLast ? "50" : "100"
          }`}
        />
      </div>
    </div>
  );
};

export default SlidesNav;
