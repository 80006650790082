import React, { useEffect, useState } from "react";
import {
  SeriesAttrsRecursiveFragment,
  EditModulePanelFragment,
  EditSlidesModalFragment
} from "generated/graphql";
import SortableTree, { TreeItem } from "react-sortable-tree";
import EditModulePanel from "components/EditModulePanel";

interface Props {
  series: SeriesAttrsRecursiveFragment;
}

function getTreeData(seriess: SeriesAttrsRecursiveFragment[]): TreeItem[] {
  const treeData: TreeItem[] = [];
  return seriess.reduce((accum, curr) => {
    return [
      ...accum,
      {
        ...curr,
        children: [
          ...curr.seriesModules.map(sm => sm.module),
          // @ts-ignore
          ...getTreeData(curr.children)
        ]
      }
    ];
  }, treeData);
}

type ProgramSeriesModule = EditModulePanelFragment & EditSlidesModalFragment;

export default function ProgramSeries(props: Props) {
  const { series } = props;
  const [panelModuleId, setPanelModuleId] = useState<string | undefined>(
    undefined
  );
  const [treeData, setTreeData] = useState<TreeItem[]>(
    getTreeData([{ ...series }])
  );
  useEffect(() => {
    setTreeData(getTreeData([{ ...series }]));
  }, [series]);
  return (
    <div className="flex-1 h-screen xl:overflow-y-auto">
      <div className="max-w-3xl py-10 px-4 sm:px-6 lg:py-12 lg:px-8">
        <div className="h-screen">
          <SortableTree
            treeData={treeData}
            getNodeKey={({ node }) => `${node.__typename}.${node.id}`}
            onChange={treeData => setTreeData(treeData)}
            generateNodeProps={({ node }) => {
              if (node.__typename === "Module") {
                return {
                  buttons: [
                    <button
                      onClick={() => {
                        setPanelModuleId((node as ProgramSeriesModule).id);
                      }}
                    >
                      View
                    </button>
                  ]
                };
              }

              return {
                buttons: []
              };
            }}
          />
        </div>
      </div>
      <EditModulePanel
        moduleId={panelModuleId}
        onClose={() => setPanelModuleId(undefined)}
      />
    </div>
  );
}
