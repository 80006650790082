export const FONTS = "Inter";
// const SYSTEM_FONTS = '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Fira Sans","Droid Sans","Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"'

const preset: any = {
  breakpoints: ["480px", "736px", "980px", "1280px"],
  colors: {
    text: "#000",
    background: "#fff",

    white: "#FFF",
    black: "#000",
    gray: "#808080",
    lightGray: "#E5E5E5",
    lighterGray: "#F2F2F2",
    backgroundGray: "#FAFAFA",
    yellow: "#FFFF00",
    warning: "#FFFBE6"
  },
  fonts: {
    body: FONTS,
    heading: FONTS,
    monospace: FONTS
  },
  fontWeights: {
    body: 400,
    heading: 700,
    bold: 700
  },
  sizes: {
    wide: 1280,
    avatar: 48
  },
  shadows: {
    small: `0 0 0px 1px rgba(0, 0, 0, 0.25)`
  },
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64, 96],
  lineHeights: {
    body: "140%",
    heading: 1.25
  },
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  radii: {
    default: 4,
    circle: 99999
  },
  card: {
    p: 2,
    bg: "background",
    boxShadow: "rgba(0, 0, 0, 0.125) 0px 0px 4px"
  },
  buttons: {
    primary: {
      fontFamily: FONTS,
      fontSize: 2,
      fontWeight: "bold",
      color: "background",
      bg: "black",
      borderRadius: "default",
      cursor: "pointer"
    },
    outline: {
      fontFamily: FONTS,
      variant: "buttons.primary",
      color: "black",
      bg: "transparent",
      boxShadow: "inset 0 0 2px"
    },
    secondary: {
      fontFamily: FONTS,
      variant: "buttons.primary",
      color: "background",
      bg: "gray"
    },
    white: {
      fontFamily: FONTS,
      variant: "buttons.primary",
      color: "black",
      bg: "white"
    },
    gray: {
      fontFamily: FONTS,
      variant: "buttons.primary",
      color: "#b2b2b2",
      bg: "#f2f2f2"
    },
    green: {
      fontFamily: FONTS,
      variant: "buttons.primary",
      color: "white",
      bg: "#50C878"
    },
    alert: {
      fontFamily: FONTS,
      variant: "buttons.primary",
      color: "white",
      bg: "#E12232"
    }
  }
};

const final = {
  useCustomProperties: true,
  maxPhoneWidth: 480,
  text: {
    serif: {
      display: "inline",
      fontFamily: "Reforma2018"
    },
    secondary: {
      fontSize: 1,
      whiteSpace: "pre-line",
      color: "gray",
      lineHeight: "140%"
    },
    primary: {
      fontSize: 1,
      color: "primary",
      whiteSpace: "pre-line",
      lineHeight: "1.3em",
      display: "inline"
    },
    display: {
      whiteSpace: "pre-line",
      fontFamily: "heading",
      fontWeight: "heading",
      lineHeight: "heading",
      fontSize: [5, 6, 7]
    },
    caps: {
      whiteSpace: "pre-line",
      textTransform: "uppercase",
      letterSpacing: "0.1em"
    },
    heading: {
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
      a: {
        color: "inherit",
        textDecoration: "none",
        ":hover": {
          textDecoration: "underline"
        }
      }
    }
  },
  variants: {
    badge: {
      display: "inline-block",
      px: 2,
      color: "background",
      bg: "black",
      borderRadius: "circle"
    },
    variants: {
      card: {
        p: 2,
        bg: "background",
        boxShadow: "rgba(0, 0, 0, 0.125) 0px 0px 4px"
      },
      link: {
        color: "black"
      }
    }
  },
  styles: {
    a: {
      color: "black",
      transition: "color .2s ease-out",
      ":hover,:focus,:active,:visited": {
        color: "black"
      }
    },
    inlineCode: {
      fontFamily: "monospace",
      fontSize: "93.75%",
      color: "gray"
    },
    code: {
      fontFamily: "monospace",
      color: "gray"
    },
    pre: {
      fontFamily: "monospace",
      fontSize: 1,
      overflowX: "auto",
      bg: "muted",
      p: 3,
      borderRadius: 4
    },
    blockquote: {
      p: 0,
      mx: 0,
      fontWeight: "bold",
      fontSize: 3
    },
    h1: {
      variant: "text.heading",
      mt: 0,
      fontSize: [5, 6]
    },
    h2: {
      variant: "text.heading",
      fontSize: [4, 5]
    },
    h3: {
      variant: "text.heading",
      fontSize: 3
    },
    h4: { variant: "text.heading" },
    h5: { variant: "text.heading" },
    h6: { variant: "text.heading" },
    table: {
      width: "100%",
      borderCollapse: "separate",
      borderSpacing: 0
    },
    th: {
      py: 2,
      textAlign: "left",
      // @ts-ignore
      borderBottom: t => `4px solid ${t.colors.muted}`
    },
    td: {
      py: 2,
      textAlign: "left",
      // @ts-ignore
      borderBottom: t => `1px solid ${t.colors.muted}`
    }
  },
  forms: {
    label: {
      fontSize: 1
    },
    field: {
      fontFamily: FONTS,
      borderColor: "lightgray",
      ":focus": {
        borderColor: "black",
        outline: "none",
        // @ts-ignore
        boxShadow: t => `0 0 0 2px ${t.colors.black}`
      }
    },
    input: {
      fontFamily: FONTS,
      variant: "forms.field"
    },
    select: {
      variant: "forms.field"
    },
    textarea: {
      variant: "forms.field"
    },
    radio: {},
    slider: {
      bg: "lightgray"
    },
    switch: {
      // thumb: {}
    }
  },
  ...preset
};

export default final;
