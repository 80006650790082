import React from "react";

import { SlideType } from "generated/graphql";
import DocumentSlide from "./slides/DocumentSlide";
import TakeawaySlide from "./slides/TakeawaySlide";
import ActivitySlide from "./slides/ActivitySlide";
import SetPlanSlide from "./slides/SetPlanSlide";
import RecordPainSlide from "./slides/RecordPainSlide";
import NoContentSlide from "./slides/NoContentSlide";

import ChooseAvatarSlide from "./slides/ChooseAvatarSlide";
import ComponentSlide from "./slides/ComponentSlide";
import { useCarouselSlide } from "./utils";
import { FormSlide, DocumentContent, TakeawayContent } from "./types";

interface SlideProps {
  slide: FormSlide;
}

function SlideComponent(props: SlideProps) {
  switch (props.slide.type) {
    case SlideType.Document:
      return <DocumentSlide content={props.slide.content as DocumentContent} />;

    case SlideType.Takeaway:
      return <TakeawaySlide content={props.slide.content as TakeawayContent} />;

    case SlideType.RecordPain:
      return <RecordPainSlide />;

    case SlideType.Activity:
      return <ActivitySlide />;

    case SlideType.Component:
      return <ComponentSlide />;

    case SlideType.ChooseAvatar:
      return <ChooseAvatarSlide />;

    case SlideType.SetPlan:
      return <SetPlanSlide />;

    case SlideType.ShouldRemind:
      return <NoContentSlide title="should remind" />;

    default:
      return <NoContentSlide title={props.slide.type} />;
  }
}

export default function SlidesCarousel() {
  const slide = useCarouselSlide();
  return <SlideComponent slide={slide} />;
}
