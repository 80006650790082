import React, { ReactElement, useState } from "react";
import { navigate, RouteComponentProps } from "@reach/router";
import { Button, Flex, Heading, Text } from "rebass";
import { Label, Input } from "@rebass/forms";
import { useForm } from "react-hook-form";

import { useLoginMutation } from "generated/graphql";

interface ValidValues {
  email: string;
  password: string;
}

export default function Login(_: RouteComponentProps): ReactElement {
  const { register, handleSubmit } = useForm<ValidValues>();
  const [loginError, setError] = useState<string | undefined>();
  const [mutation, { loading }] = useLoginMutation({
    onCompleted: ({ login }) => {
      if (login.error) {
        setError(login.error || undefined);
        return;
      } else {
        navigate("/");
      }
    }
  });

  return (
    <Flex
      as="form"
      onSubmit={handleSubmit(data => {
        setError(undefined);
        mutation({ variables: data });
      })}
      px={[3, 4, 5]}
      height="100vh"
      width={[1, 1, 1, 1 / 2]}
      flexDirection="column"
      justifyContent="center"
    >
      <Heading fontSize={[5, 5, 6]} mb="2">
        Login
      </Heading>
      <Flex mb={3} flexDirection="column">
        <Label py="2" htmlFor="email">
          email
        </Label>
        <Input
          id="email"
          name="email"
          type="email"
          autoComplete="off"
          placeholder="hello@example.com"
          ref={register({ required: true, maxLength: 80 })}
        />
        <Label py="2" htmlFor="password">
          password
        </Label>
        <Input
          id="password"
          type="password"
          autoComplete="off"
          placeholder="•••••••••"
          name="password"
          ref={register({ required: true, maxLength: 100 })}
        />
      </Flex>
      <Button type="submit">{loading ? "Authenticating..." : "Login"}</Button>
      {loginError && <Text mt={3}>{loginError}</Text>}
    </Flex>
  );
}
