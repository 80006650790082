import React, { ReactElement } from "react";
import { Button, SxStyleProp } from "rebass";
import { navigate } from "@reach/router";
import { useLogoutMutation } from "generated/graphql";

import client from "lib/apollo";

export enum Variant {
  Primary = "primary",
  Gray = "gray"
}

interface Props {
  sx?: SxStyleProp;
  variant?: Variant;
}

export default function LogoutButton({
  sx = {},
  variant = Variant.Primary
}: Props): ReactElement {
  const [logout] = useLogoutMutation({
    onCompleted: () => {
      navigate("/login");
      client.clearStore();
    }
  });
  return (
    <Button
      mt="10px"
      variant={variant}
      onClick={() => {
        logout();
      }}
      sx={{
        ...sx
      }}
    >
      Logout
    </Button>
  );
}
