import React from "react";

import Loader from "components/common/Loader";
import PanelWrapper from "components/common/PanelWrapper";
import PanelHeader from "components/common/PanelHeader";

const PanelLoading = () => {
  return (
    <PanelWrapper
      header={<PanelHeader title="" subtitle="" />}
      isOpen={false}
      onClose={() => {}}
    >
      <div className="mt-6">
        <Loader color="#000" size={54} />
      </div>
    </PanelWrapper>
  );
};

export default PanelLoading;
