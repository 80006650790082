import {
  from,
  ApolloClient,
  HttpLink,
  InMemoryCache,
  ApolloLink
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";

import { navigate } from "@reach/router";

const errLink = onError(({ graphQLErrors }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(err => {
      if (err.message === "Login is required") {
        navigate("/login");
      } else {
        console.error(err.message, err.path);
      }
    });
  }
});

const cache = new InMemoryCache();

const tzLink = new ApolloLink((operation, forward) => {
  operation.setContext((context: any) => {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return {
      ...context,
      headers: {
        ...context.headers,
        "X-Timezone": timezone
      }
    };
  });
  return forward(operation);
});

const link = from([
  errLink,
  tzLink,
  new HttpLink({
    uri: `${process.env.REACT_APP_FUNCTION_API_URL}/graphql/`,
    credentials: "include"
  })
]);

export default new ApolloClient({
  cache,
  link,
  connectToDevTools: true
});
