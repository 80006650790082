import React, { createContext, ReactElement, ReactNode, Dispatch } from "react";

import {
  useCarouselReducer,
  getInitialState
} from "components/SlidesCarousel/Reducer";
import { Slide } from "generated/graphql";
import { Action, State } from "./types";

export interface CarouselContextState extends State {
  dispatch: Dispatch<Action>;
}

interface CarouselProviderProps {
  slides: Slide[];
  children: ReactNode;
}

const initSlides: Slide[] = [];

const defaultContext = {
  dispatch: () => {},
  ...getInitialState(initSlides)
};

const CarouselContext = createContext<CarouselContextState>(defaultContext);

export function CarouselProvider(props: CarouselProviderProps): ReactElement {
  const [state, dispatch] = useCarouselReducer(props.slides);

  return (
    <CarouselContext.Provider
      value={{
        ...state,
        dispatch
      }}
    >
      {props.children}
    </CarouselContext.Provider>
  );
}

export default CarouselContext;
