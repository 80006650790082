import React, { ReactElement } from "react";
import { ApolloProvider } from "@apollo/client";
import { ThemeProvider } from "emotion-theming";
import { LocationProvider, Router } from "@reach/router";
import "react-sortable-tree/style.css";

import client from "lib/apollo";
import theme from "assets/theme";

import AdminRoute from "components/AdminRoute";
import ErrorBoundary from "components/ErrorBoundary";
import ErrorBoundaryRaw from "components/ErrorBoundaryRaw";

import MePage from "pages/MePage";

import SettingsPage from "pages/SettingsPage";
import LoginPage from "pages/LoginPage";
import NotFoundPage from "pages/NotFoundPage";
import UnauthedNotFoundPage from "pages/UnauthedNotFound";
import AdminPage from "pages/AdminPage";

export default function App(): ReactElement {
  return (
    <LocationProvider>
      <ErrorBoundaryRaw>
        <ThemeProvider theme={theme}>
          <ApolloProvider client={client}>
            <ErrorBoundary>
              <Router primary={false}>
                <LoginPage path="/login" />
                <MePage path="/">
                  <SettingsPage path="/settings" />
                  <AdminRoute as={AdminPage} path="/*" />
                  <NotFoundPage default />
                </MePage>
                <UnauthedNotFoundPage default />
              </Router>
            </ErrorBoundary>
          </ApolloProvider>
        </ThemeProvider>
      </ErrorBoundaryRaw>
    </LocationProvider>
  );
}
