import {
  EditModulePanelFragment,
  UpdateModulePayload
} from "generated/graphql";

export function cleanModule(
  formModule: EditModulePanelFragment
): UpdateModulePayload {
  return {
    title: formModule.title,
    duration: formModule.duration,
    type: formModule.type,
    onboardingTriggers: formModule.onboardingTriggers.map(t => ({
      key: t.key
    })),
    templateBaseTasks: formModule.templateTaskTriggers.map(t => ({
      id: t.templateBaseTask.id
    })),
    treatments: formModule.treatmentTriggers.map(t => ({
      id: t.treatment.id
    }))
  };
}
