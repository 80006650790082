import React, { useEffect, useState } from "react";
import SortableTree, { TreeItem } from "react-sortable-tree";
import EditModulePanel from "components/EditModulePanel";
import {
  TreatmentAttrsFragment,
  TreatmentAttrsRecursiveFragment
} from "generated/graphql";

interface Props {
  treatmentAreasAdmin: TreatmentAttrsRecursiveFragment[];
}

function getTreeData(
  treatments: TreatmentAttrsRecursiveFragment[]
): TreeItem[] {
  const treeData: TreeItem[] = [];
  return treatments.reduce((accum, curr) => {
    return [
      ...accum,
      {
        title: curr.name,
        ...curr,
        children: [
          ...curr.modules,
          // @ts-ignore
          ...getTreeData(curr.adminChildren)
        ]
      }
    ];
  }, treeData);
}

export default function TreatmentBodyContent(props: Props) {
  const { treatmentAreasAdmin } = props;
  const [panelModuleId, setPanelModuleId] = useState<string | undefined>(
    undefined
  );
  const [treeData, setTreeData] = useState<TreeItem[]>(
    getTreeData([...treatmentAreasAdmin])
  );
  useEffect(() => {
    setTreeData(getTreeData([...treatmentAreasAdmin]));
  }, [treatmentAreasAdmin]);

  console.log(treeData);

  return (
    <div className="flex-1 h-screen xl:overflow-y-auto">
      <div className="max-w-3xl py-10 px-4 sm:px-6 lg:py-12 lg:px-8">
        <div className="h-screen">
          <SortableTree
            treeData={treeData}
            getNodeKey={({ node }) => `${node.__typename}.${node.id}`}
            onChange={treeData => setTreeData(treeData)}
            generateNodeProps={({ node }) => {
              if (node.__typename === "Module") {
                return {
                  buttons: [
                    <button
                      onClick={() => {
                        setPanelModuleId(
                          (node as TreatmentAttrsFragment["modules"][0]).id
                        );
                      }}
                    >
                      View module
                    </button>
                  ]
                };
              }
              if (node.__typename === "Treatment") {
                return {
                  buttons: [
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={`${process.env.REACT_APP_FUNCTION_API_URL}/admin/treatments/treatment/${node.id}/change/`}
                    >
                      <button>View treatment</button>
                    </a>
                  ]
                };
              }

              return {
                buttons: []
              };
            }}
          />
        </div>
      </div>
      <EditModulePanel
        moduleId={panelModuleId}
        onClose={() => setPanelModuleId(undefined)}
      />
    </div>
  );
}
