import React, { useContext } from "react";

import {
  ModulePartsFragmentDoc,
  useUpdateModuleSlidesMutation
} from "generated/graphql";
import CarouselContext from "components/SlidesCarousel/Context";
import {
  serializeFormSlides,
  cleanSlides
} from "components/SlidesCarousel/utils";

interface Props {
  moduleId: string;
  onClose: () => void;
}

export default function EditSlidesButton(props: Props) {
  const { formSlides } = useContext(CarouselContext);
  const [mutation, { loading }] = useUpdateModuleSlidesMutation({
    onCompleted: () => {
      props.onClose();
    },
    update: (cache, { data }) => {
      if (!!data) {
        cache.modify({
          fields: {
            modules() {
              cache.writeFragment({
                data: data.updateModuleSlides,
                fragment: ModulePartsFragmentDoc
              });
            }
          }
        });
      }
    }
  });
  return (
    <button
      type="button"
      className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm"
      onClick={() => {
        mutation({
          variables: {
            payload: {
              moduleId: props.moduleId,
              slides: cleanSlides(serializeFormSlides(formSlides))
            }
          }
        });
      }}
    >
      {loading ? "Loading..." : "Save"}
    </button>
  );
}
