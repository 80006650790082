import React from "react";
import { EditModulePanelFragment, useTreatmentsQuery } from "generated/graphql";
import MultipleSelect from "components/MultipleSelect";
import PanelFormField, {
  PanelFormInput
} from "components/common/PanelFormField";

interface Props {
  treatmentTriggers: EditModulePanelFragment["treatmentTriggers"];
  onChange: (
    value:
      | {
          label: string;
          value: string;
        }[]
      | null
  ) => void;
}

export default function TreatmentTriggers(props: Props) {
  const { data, loading, error } = useTreatmentsQuery();

  if (loading) {
    <PanelFormField label="Treatment triggers">
      <PanelFormInput className="cursor-not-allowed bg-gray-200" />
    </PanelFormField>;
  }
  if (error || !data) {
    return null;
  }

  return (
    <PanelFormField label="Treatment triggers">
      <MultipleSelect<
        {
          label: string;
          value: string;
        }[]
      >
        value={props.treatmentTriggers.map(t => ({
          label: t.treatment.name,
          value: t.treatment.id
        }))}
        onChange={(
          value:
            | {
                label: string;
                value: string;
              }[]
            | null
        ) => props.onChange(value)}
        // @ts-ignore
        options={data.treatments.map(t => ({
          label: t.name,
          value: t.id
        }))}
      />
    </PanelFormField>
  );
}
