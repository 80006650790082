import React from "react";
import SlidesNav from "components/SlidesCarousel/SlidesNav";
import SlidesMenu from "components/SlidesCarousel/SlidesMenu";

export default function SetPlanSlide() {
  return (
    <>
      <div className="flex flex-col items-start">
        <div className="flex justify-between items-center">
          <div className="text-md text-gray-900">Set plan</div>
          <SlidesNav />
        </div>
        <SlidesMenu />
      </div>
      <div
        className="mb-4 h-full p-4 border-2 border-gray-200 rounded-3xl min-h-[640px]"
        style={{ minHeight: 640 }}
        aria-hidden="true"
      >
        <p className="text-sm text-gray-500">set plan placeholder</p>
      </div>
    </>
  );
}
