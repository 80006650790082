import React, { useState } from "react";

import { OnboardingTriggerKeyToVal, OnboardingTriggers } from "lib/onboarding";
import MultipleSelect from "components/MultipleSelect";
import EditModuleButton from "components/EditModulePanel/EditModuleButton";
import {
  EditModulePanelFragment,
  EditSlidesModalFragment,
  ModuleType,
  OnboardingTriggerKey
} from "generated/graphql";
import EditSlidesModal from "components/EditSlidesModal";
import Select from "components/Select";
import { ModuleTypeToLabel, moduleTypes } from "lib/modules";
import DeleteModuleModal from "components/EditModulePanel/DeleteModuleModal";
import PanelFooter from "components/common/PanelFooter";
import TaskTriggers from "components/EditModulePanel/TaskTriggers";
import TreatmentTriggers from "components/EditModulePanel/TreatmentTriggers";
import PanelFormField, {
  PanelFormInput,
  PanelButton,
  ButtonVariant
} from "components/common/PanelFormField";
import TaskScheduleTriggers from "components/EditModulePanel/TaskScheduleTriggers";

interface Props {
  module: EditModulePanelFragment & EditSlidesModalFragment;
  onClose: () => void;
}

export default function EditModuleBody(props: Props) {
  const { module, onClose } = props;
  const [editSlidesOpen, setEditSlidesOpen] = useState<boolean>(false);
  const [deleteModuleOpen, setDeleteModuleOpen] = useState<boolean>(false);

  const [formModule, setFormModule] = useState<EditModulePanelFragment>(module);

  return (
    <>
      <div className="py-6 space-y-6 sm:py-0 sm:space-y-0 sm:divide-y sm:divide-gray-200 mb-20">
        <PanelFormField label="Title">
          <PanelFormInput
            type="text"
            value={formModule.title || ""}
            onChange={e =>
              setFormModule({
                ...formModule,
                title: e.target.value
              })
            }
            name="title"
            id="title"
          />
        </PanelFormField>
        <PanelFormField label="Duration">
          <PanelFormInput
            type="text"
            value={formModule.duration || ""}
            onChange={e =>
              setFormModule({
                ...formModule,
                duration: parseInt(e.target.value, 10)
              })
            }
            name="duration"
            id="duration"
          />
        </PanelFormField>
        <PanelFormField label="Module type">
          <Select
            value={formModule.type}
            onChange={e => {
              setFormModule({
                ...formModule,
                type: e.target.value as ModuleType
              });
            }}
            // @ts-ignore
            options={moduleTypes.map(t => ({
              value: t,
              label: ModuleTypeToLabel[t]
            }))}
          />
        </PanelFormField>
        <TaskTriggers
          templateTaskTriggers={formModule.templateTaskTriggers}
          onChange={(
            value:
              | {
                  label: string;
                  value: string;
                }[]
              | null
          ) => {
            setFormModule({
              ...formModule,
              templateTaskTriggers: (value || []).map(option => ({
                templateBaseTask: {
                  title: option.label,
                  id: option.value
                }
              }))
            });
          }}
        />
        <TreatmentTriggers
          treatmentTriggers={formModule.treatmentTriggers}
          onChange={(
            value:
              | {
                  label: string;
                  value: string;
                }[]
              | null
          ) => {
            setFormModule({
              ...formModule,
              treatmentTriggers: (value || []).map(option => ({
                treatment: {
                  name: option.label,
                  id: option.value
                }
              }))
            });
          }}
        />
        <PanelFormField label="Onboarding triggers">
          <MultipleSelect<
            {
              label: string;
              value: OnboardingTriggerKey;
            }[]
          >
            value={formModule.onboardingTriggers.map(t => ({
              label: OnboardingTriggerKeyToVal[t.key],
              value: t.key
            }))}
            onChange={(
              value:
                | {
                    label: string;
                    value: OnboardingTriggerKey;
                  }[]
                | null
            ) => {
              setFormModule({
                ...formModule,
                onboardingTriggers: (value || []).map(option => ({
                  key: option.value
                }))
              });
            }}
            //@ts-ignore
            options={OnboardingTriggers.map(t => ({
              label: OnboardingTriggerKeyToVal[t],
              value: t
            }))}
          />
        </PanelFormField>
        <TaskScheduleTriggers
          templateScheduleTaskTriggers={
            props.module.templateScheduleTaskTriggers
          }
          templateUnscheduleTaskTriggers={
            props.module.templateUnscheduleTaskTriggers
          }
        />
        <div className="space-y-2 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
          <PanelButton
            onClick={() => setEditSlidesOpen(true)}
            label="Edit slides"
            variant={ButtonVariant.Green}
          />
          <a
            href={`https://api.functionrelief.com/admin/modules/module/${props.module?.id}/change/`}
            target="_blank"
            rel="noreferrer"
          >
            <PanelButton label="Edit references" variant={ButtonVariant.Gray} />
          </a>
        </div>
        <div className="space-y-2 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
          <PanelButton
            label="Delete module"
            variant={ButtonVariant.Red}
            onClick={() => setDeleteModuleOpen(true)}
          />
        </div>
      </div>
      <PanelFooter>
        <PanelButton
          label="Cancel"
          onClick={onClose}
          variant={ButtonVariant.Gray}
        />
        <EditModuleButton
          onClose={onClose}
          formModule={formModule as EditModulePanelFragment}
        />
      </PanelFooter>
      <EditSlidesModal
        module={module as EditSlidesModalFragment}
        isOpen={editSlidesOpen}
        onClose={() => setEditSlidesOpen(false)}
      />
      <DeleteModuleModal
        moduleId={module?.id as string}
        isOpen={deleteModuleOpen}
        onClosePanel={onClose}
        onClose={() => setDeleteModuleOpen(false)}
      />
    </>
  );
}
