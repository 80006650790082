import React, { ReactElement, ReactNode } from "react";

interface Props {
  onClick: () => void;
  className?: string;
  children: ReactNode;
}

function classNames(classes: (string | undefined)[]) {
  return classes.filter(Boolean).join(" ");
}

export default function CircleButton(props: Props): ReactElement {
  return (
    <div
      onClick={props.onClick}
      className={classNames([
        "flex shadow rounded-full h-8 w-8 bg-white justify-center items-center pointer",
        props.className
      ])}
    >
      {props.children}
    </div>
  );
}
