import React from "react";
import { keyframes } from "@emotion/core";
import styled from "@emotion/styled";
import { useTheme } from "emotion-theming";

import { ReactComponent as WhiteSpinner } from "../../assets/icons/spinner-white.svg";

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const Rotate = styled.div`
  display: block;
  animation: ${rotate} 0.75s linear infinite;
`;

interface LoaderProps {
  color?: string;
  size?: number;
}

export default function Loader(props: LoaderProps) {
  const { colors } = useTheme();
  const Spinner = styled(WhiteSpinner)({
    height: props.size || 16,
    width: props.size || 16,
    display: "block",
    margin: "auto",
    path: {
      fill: props.color || colors.white
    }
  });
  return (
    <Rotate>
      <Spinner />
    </Rotate>
  );
}
