import { OnboardingTriggerKey } from "generated/graphql";

export const OnboardingTriggerKeyToVal = {
  [OnboardingTriggerKey.Progress]: "Progress",
  [OnboardingTriggerKey.Scoreboard]: "Scoreboard",
  [OnboardingTriggerKey.Treatments]: "Treatments",
  [OnboardingTriggerKey.Evidence]: "Evidence"
};

export const OnboardingTriggers = [
  OnboardingTriggerKey.Progress,
  OnboardingTriggerKey.Scoreboard,
  OnboardingTriggerKey.Treatments,
  OnboardingTriggerKey.Evidence
];
