import React from "react";
import moment from "moment";

import { ProgramRowFragment } from "generated/graphql";
import { navigate } from "@reach/router";

interface Props extends ProgramRowFragment {}

export default function ProgramRow(props: Props) {
  return (
    <tr key={props.id}>
      <td className="px-6 py-3 max-w-0 w-full whitespace-nowrap text-sm font-medium text-gray-900">
        <div className="flex items-center space-x-3 lg:pl-2">
          <span
            onClick={() => navigate(`/programs/${props.id}`)}
            className="truncate hover:text-gray-600 cursor-pointer"
          >
            {props.title}
          </span>
        </div>
      </td>
      <td className="px-6 py-3 text-sm text-gray-500 font-medium">
        <div className="flex items-center space-x-2">{props.numUsers}</div>
      </td>
      <td className="hidden md:table-cell px-6 py-3 whitespace-nowrap text-sm text-gray-500 text-right">
        {moment(props.createdAt).format("LL")}
      </td>
    </tr>
  );
}
