import React, { useContext } from "react";

import SlidesCarouselContext from "components/SlidesCarousel/Context";

import SlidesNav from "components/SlidesCarousel/SlidesNav";
import SlidesMenu from "components/SlidesCarousel/SlidesMenu";

interface Props {
  content: { text: string };
}

export default function ModuleTakeaway(props: Props) {
  const { dispatch } = useContext(SlidesCarouselContext);
  return (
    <>
      <div className="flex flex-col w-full items-start">
        <div className="flex justify-between  w-full items-center">
          <div className="text-md text-gray-900">Takeaway</div>
          <SlidesNav />
        </div>
        <SlidesMenu />
      </div>
      <div
        className="font-display flex h-full p-4 border-2 border-gray-200 rounded-3xl justify-center items-center"
        style={{ minHeight: 640 }}
        aria-hidden="true"
      >
        <textarea
          value={props.content.text}
          rows={5}
          onChange={e =>
            dispatch({
              type: "updateModuleTakeaway",
              value: { text: e.target.value as string }
            })
          }
          name="title"
          id="title"
          className="block w-full shadow-sm focus:ring-green-500 focus:border-green-500 border-gray-300 rounded-md text-xl text-gray-900 text-center"
        />
      </div>
    </>
  );
}
