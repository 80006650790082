import React from "react";
import { RouteComponentProps } from "@reach/router";

import Error from "components/Error";
import Loader from "components/common/Loader";
import { useAdminTreatmentAreasQuery } from "generated/graphql";

import TreatmentBodyContent from "components/Admin/treatments/TreatmentBodyContent";

interface Props extends RouteComponentProps {
  programId?: string;
}

export default function TreatmentsBody(props: Props) {
  const { data, loading, error } = useAdminTreatmentAreasQuery({
    variables: { programId: props.programId as string }
  });

  if (loading) {
    return (
      <div className="mt-12">
        <Loader color="#000" size={54} />
      </div>
    );
  }

  if (error || data === undefined || !data) {
    return <Error componentName="ProgramBody" error={error} />;
  }

  return (
    <main className="flex-1 flex overflow-hidden">
      <div className="flex-1 flex flex-col overflow-y-auto overflow-hidden">
        <div className="flex-1 flex overflow-hidden">
          {data.treatmentAreasAdmin.length === 0 ? (
            <div>this program doesn't have any treatments</div>
          ) : (
            <TreatmentBodyContent
              treatmentAreasAdmin={[...data.treatmentAreasAdmin]}
            />
          )}
        </div>
      </div>
    </main>
  );
}
