import React, { useState } from "react";

import { ReactComponent as Menu16 } from "assets/icons/menu-16.svg";
import CircleButton from "components/CircleButton";

export default function MoreButton() {
  const [isActive, setIsActive] = useState<boolean>(false);
  return (
    <CircleButton onClick={() => setIsActive(!isActive)}>
      <Menu16 className="path-current fill-current text-gray-600" />
    </CircleButton>
  );
}
