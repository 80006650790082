import React from "react";

import {
  EditModulePanelFragment,
  GetProgramDocument,
  useUpdateModuleMutation
} from "generated/graphql";
import { cleanModule } from "./utils";
import { useParams } from "@reach/router";

interface Props {
  formModule: EditModulePanelFragment;
  onClose: () => void;
}

export default function EditModuleButton(props: Props) {
  const params = useParams();
  const [mutation, { loading }] = useUpdateModuleMutation({
    onCompleted: () => {
      props.onClose();
    },
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GetProgramDocument,
        variables: { programId: params.programId || "" }
      }
    ]
  });
  return (
    <button
      type="button"
      className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm"
      onClick={() => {
        mutation({
          variables: {
            moduleId: props.formModule.id,
            payload: cleanModule(props.formModule)
          }
        });
      }}
    >
      {loading ? "Loading..." : "Save"}
    </button>
  );
}
