import React, { createContext, ReactElement, ReactNode, Dispatch } from "react";

import {
  Action as MeAction,
  State as MeState,
  useMeReducer,
  getInitialState
} from "contexts/MeReducer";
import { MeQuery } from "generated/graphql";

export interface MeContextState extends MeState {
  dispatch: Dispatch<MeAction>;
}

interface MeProviderProps {
  me: MeQuery["me"];
  children: ReactNode;
}

export const mockMe = {
  email: "",
  fullName: "",
  isAdmin: false
};

const defaultContext = {
  dispatch: () => {},
  ...getInitialState(mockMe)
};

const MeContext = createContext<MeContextState>(defaultContext);

export function MeProvider(props: MeProviderProps): ReactElement {
  const [state, dispatch] = useMeReducer(props.me);

  return (
    <MeContext.Provider
      value={{
        ...state,
        dispatch
      }}
    >
      {props.children}
    </MeContext.Provider>
  );
}

export default MeContext;
