import React, {
  ButtonHTMLAttributes,
  InputHTMLAttributes,
  ReactNode
} from "react";

interface Props {
  label: string;
  children: ReactNode;
}
interface InputProps extends InputHTMLAttributes<HTMLInputElement> {}

export enum ButtonVariant {
  Green = "GREEN",
  Red = "RED",
  Gray = "GRAY"
}
interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant: ButtonVariant;
  label: string;
}

const PanelFormField = (props: Props) => {
  return (
    <div className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
      <label
        htmlFor={props.label}
        className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
      >
        {props.label}
      </label>
      <div className="sm:col-span-2">{props.children}</div>
    </div>
  );
};

export const PanelFormInput = (props: InputProps) => {
  return (
    <input
      {...props}
      className="block w-full shadow-sm sm:text-sm focus:ring-green-500 focus:border-green-500 border-gray-300 rounded-md"
    />
  );
};

const ButtonVariantToTW = {
  [ButtonVariant.Green]:
    "w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:mr-3 sm:w-auto sm:text-sm",
  [ButtonVariant.Gray]:
    "bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500",
  [ButtonVariant.Red]:
    "w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:mr-3 sm:w-auto sm:text-sm"
};

export const PanelButton = ({ className, label, ...props }: ButtonProps) => {
  return (
    <button
      type="button"
      className={`${ButtonVariantToTW[props.variant]} ${className}`}
      {...props}
    >
      {label}
    </button>
  );
};

export default PanelFormField;
