import React from "react";
import { useSelected, useFocused } from "slate-react";

// @ts-ignore
const ImageElement = ({ attributes, children, element }) => {
  const selected = useSelected();
  const focused = useFocused();
  return (
    <div {...attributes}>
      <div contentEditable={false}>
        <img
          alt=""
          src={element.url}
          className={`${
            selected && focused
              ? "border-2 border-indigo-400"
              : "border-2 border-white"
          }`}
        />
      </div>
      {children}
    </div>
  );
};

export default ImageElement;
