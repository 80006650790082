import React, { ReactNode, ReactElement } from "react";
import { Flex } from "rebass";
import { RouteComponentProps } from "@reach/router";

import { MeProvider } from "contexts/MeContext";
import Loader from "components/common/Loader";
import ErrorPage from "pages/ErrorPage";
import { useMeQuery } from "generated/graphql";

interface Props extends RouteComponentProps {
  children: ReactNode;
}

export default function MePage(props: Props): ReactElement {
  const { data, error, loading } = useMeQuery({
    fetchPolicy: "no-cache"
  });

  if (loading) {
    return (
      <Flex pt="200px" justifyContent="center">
        <Loader color="#000" size={54} />
      </Flex>
    );
  }

  if (error || data === undefined) {
    return <ErrorPage error={error} componentName="MePage" />;
  }

  return <MeProvider me={data.me}>{props.children}</MeProvider>;
}
