import React from "react";
import classNames from "lib/classNames";

interface Props {
  title: string;
  subtitle?: string | null;
  isActive: boolean;
  onClick: () => void;
}

export default function SidebarSubNavItem(props: Props) {
  const { title, subtitle, isActive } = props;
  return (
    <span
      className={classNames(
        "flex p-6 border-b border-blue-gray-200 cursor-pointer",
        isActive
          ? "bg-blue-50 bg-opacity-50"
          : "hover:bg-blue-50 hover:bg-opacity-50"
      )}
      aria-current="page"
      onClick={props.onClick}
    >
      <div className="ml-3 text-sm">
        <p className="font-medium text-blue-gray-900">{title}</p>
        {subtitle && <p className="mt-1 text-blue-gray-500">{subtitle}</p>}
      </div>
    </span>
  );
}
