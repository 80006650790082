import React, { ReactNode, useCallback, useMemo } from "react";
import isHotkey from "is-hotkey";
import {
  Editable,
  withReact,
  Slate,
  RenderElementProps,
  RenderLeafProps
} from "slate-react";
import { createEditor, Descendant } from "slate";
import { withHistory } from "slate-history";
import { toggleMark } from "components/Editor/EditorMenuButton";
import EditorComponent from "components/Editor/EditorComponent";
import ImageElement from "components/Editor/ImageElement";
import "tippy.js/dist/tippy.css";

import { withComponents } from "components/Editor/EditorComponent/utils";
import { withImages } from "components/Editor/ImageElement/utils";
import { BlockElement } from "generated/graphql";

const Tippy = React.lazy(() => import("@tippyjs/react"));

const HOTKEYS = {
  "mod+b": "bold",
  "mod+i": "italic",
  "mod+u": "underline"
};

interface Props {
  value: Descendant[];
  onChange: (value: Descendant[]) => void;
  header?: ReactNode;
  editorClassName?: string;
}

const Editor = (props: Props) => {
  const renderElement = useCallback(props => <Element {...props} />, []);
  const renderLeaf = useCallback(props => <Leaf {...props} />, []);
  const editor = useMemo(
    () => withImages(withComponents(withHistory(withReact(createEditor())))),
    []
  );

  console.log(props.value);

  return (
    <div className="flex flex-1 flex-col">
      <Slate editor={editor} value={props.value} onChange={props.onChange}>
        <div>{props.header}</div>
        <Editable
          className={props.editorClassName}
          style={{ minHeight: 640 }}
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          placeholder="Enter some rich text…"
          spellCheck
          autoFocus
          onKeyDown={event => {
            for (const hotkey in HOTKEYS) {
              if (isHotkey(hotkey, event as any)) {
                event.preventDefault();
                // @ts-ignore
                const mark = HOTKEYS[hotkey];
                toggleMark(editor, mark);
              }
            }
          }}
        />
      </Slate>
    </div>
  );
};

const Element = (props: RenderElementProps) => {
  const { attributes, children, element } = props;
  switch (element.type) {
    case BlockElement.HeadingOne:
      return (
        <h1
          className="font-display mb-4 text-3xl text-gray-900"
          {...attributes}
        >
          {children}
        </h1>
      );
    case BlockElement.HeadingTwo:
      return (
        <h2
          className="font-display mb-4 text-2xl text-gray-900"
          {...attributes}
        >
          {children}
        </h2>
      );
    case BlockElement.Component:
      return <EditorComponent {...props} />;
    case BlockElement.Image:
      return <ImageElement {...props} />;
    case BlockElement.BulletedList:
      return (
        <ul className="mb-4 list-disc" {...attributes}>
          {children}
        </ul>
      );

    case BlockElement.ListItem:
      return (
        <li className="ml-8 font-display text-lg text-gray-900" {...attributes}>
          {children}
        </li>
      );
    case BlockElement.NumberedList:
      return (
        <ol className=" mb-4 list-decimal" {...attributes}>
          {children}
        </ol>
      );

    default:
      return (
        <p className="font-display mb-4 text-lg text-gray-900" {...attributes}>
          {children}
        </p>
      );
  }
};

const Leaf = ({ attributes, children, leaf }: RenderLeafProps) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }

  if (leaf.italic) {
    children = <em>{children}</em>;
  }

  if (leaf.underline) {
    children = <u>{children}</u>;
  }

  if (!!leaf.url) {
    const url = typeof leaf.url === "string" ? leaf.url : "";
    children = (
      <React.Suspense fallback={children}>
        <Tippy duration={0} zIndex={1000} content={<div>{url}</div>}>
          <a className="underline text-blue-500" href={url}>
            {children}
          </a>
        </Tippy>
      </React.Suspense>
    );
  }

  return <span {...attributes}>{children}</span>;
};

export default Editor;
