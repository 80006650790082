import gql from "graphql-tag";
import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  Date: any;
  Time: any;
};

export type ActivityFilters = {
  readonly type?: Maybe<ActivityType>;
};

export type ActivityItem = GroupedTaskEvent | GoalRecording;

export enum ActivityType {
  GroupedTaskEvent = "GROUPED_TASK_EVENT",
  GoalRecording = "GOAL_RECORDING"
}

export type AssignedModule = {
  readonly __typename?: "AssignedModule";
  readonly id: Scalars["String"];
  readonly module: Module;
  readonly completedAt?: Maybe<Scalars["DateTime"]>;
  readonly isFavorite: Scalars["Boolean"];
  readonly assignedSeries?: Maybe<AssignedSeries>;
};

export type AssignedModuleResponse = {
  readonly __typename?: "AssignedModuleResponse";
  readonly error?: Maybe<Error>;
  readonly assignedModule?: Maybe<AssignedModule>;
};

export type AssignedProgram = {
  readonly __typename?: "AssignedProgram";
  readonly id: Scalars["String"];
  readonly status: AssignedProgramStatus;
  readonly program: Program;
  readonly onboarding: AssignedProgramOnboarding;
  readonly firstCompletedDay?: Maybe<Scalars["DateTime"]>;
  readonly shouldRequestFeedback: Scalars["Boolean"];
  readonly firstCompletedModule?: Maybe<AssignedModule>;
  readonly lastCompletedProgressModule?: Maybe<AssignedModule>;
  readonly momentum: AssignedProgramMomentum;
  readonly progressSeries?: Maybe<AssignedSeries>;
  readonly lastGoalRecording?: Maybe<GoalRecording>;
};

export type AssignedProgramMomentum = {
  readonly __typename?: "AssignedProgramMomentum";
  readonly percent: Scalars["Float"];
  readonly descriptor: Scalars["String"];
  readonly message: Scalars["String"];
  readonly num: Scalars["Int"];
  readonly maxNum: Scalars["Int"];
};

export type AssignedProgramOnboarding = {
  readonly __typename?: "AssignedProgramOnboarding";
  readonly progress: Scalars["Boolean"];
  readonly scoreboard: Scalars["Boolean"];
  readonly treatments: Scalars["Boolean"];
  readonly evidence: Scalars["Boolean"];
};

export type AssignedProgramResponse = {
  readonly __typename?: "AssignedProgramResponse";
  readonly error?: Maybe<Error>;
  readonly assignedProgram?: Maybe<AssignedProgram>;
};

export enum AssignedProgramStatus {
  None = "NONE",
  FreeTrial = "FREE_TRIAL",
  AwaitingPayment = "AWAITING_PAYMENT",
  Active = "ACTIVE",
  CompletedCore = "COMPLETED_CORE",
  Churned = "CHURNED"
}

export type AssignedSeries = {
  readonly __typename?: "AssignedSeries";
  readonly id: Scalars["String"];
  readonly level: Scalars["Int"];
  readonly template: Series;
  readonly parent?: Maybe<AssignedSeries>;
  readonly children: ReadonlyArray<AssignedSeries>;
  readonly completedAt?: Maybe<Scalars["DateTime"]>;
  readonly status: ProgramItemStatus;
  readonly descendantAssignedModules: ReadonlyArray<AssignedModule>;
  readonly ancestorAssignedModules: ReadonlyArray<AssignedModule>;
};

export type AssignedSeriesResponse = {
  readonly __typename?: "AssignedSeriesResponse";
  readonly assignedSeries?: Maybe<AssignedSeries>;
  readonly error?: Maybe<Error>;
};

export type AssignedTask = {
  readonly __typename?: "AssignedTask";
  readonly id: Scalars["String"];
  readonly date: Scalars["Date"];
  readonly baseTask: BaseTask;
  readonly quantity: Scalars["Int"];
  readonly pastTenseActionTitle: Scalars["String"];
  readonly wasScheduled: Scalars["Boolean"];
  readonly lastCompletedAt?: Maybe<Scalars["DateTime"]>;
  readonly instructions: ReadonlyArray<Scalars["String"]>;
  readonly statusMessage: Scalars["String"];
  readonly percentComplete: Scalars["Float"];
  readonly isComplete: Scalars["Boolean"];
  readonly quantityComplete: Scalars["Int"];
  readonly assignedModules: ReadonlyArray<AssignedModule>;
  readonly assignedTaskModules: ReadonlyArray<AssignedTaskModule>;
  readonly implementation: AssignedTaskImplementation;
};

export type AssignedTaskEvent = {
  readonly __typename?: "AssignedTaskEvent";
  readonly id: Scalars["String"];
  readonly assignedTask: AssignedTask;
  readonly quantity: Scalars["Int"];
  readonly completedAt: Scalars["DateTime"];
};

export type AssignedTaskEventResponse = {
  readonly __typename?: "AssignedTaskEventResponse";
  readonly error?: Maybe<Error>;
  readonly assignedTaskEvent?: Maybe<AssignedTaskEvent>;
};

export type AssignedTaskImplementation = {
  readonly __typename?: "AssignedTaskImplementation";
  readonly id: Scalars["String"];
  readonly type: TaskImplementationType;
  readonly assignedModules: ReadonlyArray<AssignedModule>;
};

export type AssignedTaskModule = {
  readonly __typename?: "AssignedTaskModule";
  readonly assignedTask: AssignedTask;
  readonly assignedModule: AssignedModule;
};

export enum Avatar {
  Archer = "ARCHER",
  Athletics = "ATHLETICS",
  BaseballPlayer = "BASEBALL_PLAYER",
  BasketballPlayer = "BASKETBALL_PLAYER",
  Balance = "BALANCE",
  Blindness = "BLINDNESS",
  Bodybuilder = "BODYBUILDER",
  Boxing = "BOXING",
  Climbing = "CLIMBING",
  Crunches = "CRUNCHES",
  CyclingTrack = "CYCLING_TRACK",
  Dancer = "DANCER",
  Fencing = "FENCING",
  GolfPlayer = "GOLF_PLAYER",
  Gymnastics = "GYMNASTICS",
  GymClass = "GYM_CLASS",
  Hockey = "HOCKEY",
  JumpRope = "JUMP_ROPE",
  Karate = "KARATE",
  ParalympicGames = "PARALYMPIC_GAMES",
  Passenger = "PASSENGER",
  PersonalTrainer = "PERSONAL_TRAINER",
  Rowing = "ROWING",
  Rugby = "RUGBY",
  Skateboarding = "SKATEBOARDING",
  Skating = "SKATING",
  Skiing = "SKIING",
  Snowboarding = "SNOWBOARDING",
  Soccer = "SOCCER",
  SportsFan = "SPORTS_FAN",
  StandingMan = "STANDING_MAN",
  StandingWoman = "STANDING_WOMAN",
  Stretching = "STRETCHING",
  Swimming = "SWIMMING",
  Tennis = "TENNIS",
  VolleyballPlayer = "VOLLEYBALL_PLAYER",
  Walk = "WALK",
  WalkingAid = "WALKING_AID",
  WaterPolo = "WATER_POLO",
  Windsurfing = "WINDSURFING",
  Yoga = "YOGA"
}

export type BaseTask = {
  readonly __typename?: "BaseTask";
  readonly id: Scalars["String"];
  readonly title: Scalars["String"];
  readonly subtitle: Scalars["String"];
  readonly actionTitle: Scalars["String"];
  readonly description: Scalars["String"];
  readonly iconKey: TaskIconKey;
  readonly isLocked: Scalars["Boolean"];
  readonly isSelected: Scalars["Boolean"];
  readonly instructions: ReadonlyArray<Scalars["String"]>;
  readonly history: ReadonlyArray<AssignedTask>;
  readonly profile: BaseTaskProfile;
  readonly implementation: BaseTaskImplementation;
};

export type BaseTaskImplementation = {
  readonly __typename?: "BaseTaskImplementation";
  readonly id: Scalars["String"];
  readonly type: TaskImplementationType;
  readonly taskGroup?: Maybe<TaskGroup>;
  readonly routine?: Maybe<Routine>;
  readonly breathworkConfigKey?: Maybe<BreathworkConfigKey>;
  readonly instructions: ReadonlyArray<Scalars["String"]>;
  readonly evidenceScore?: Maybe<TaskEvidenceScore>;
  readonly guidedAudioUrl?: Maybe<Scalars["String"]>;
};

export type BaseTaskProfile = {
  readonly __typename?: "BaseTaskProfile";
  readonly actionPastTense: Scalars["String"];
};

export enum BlockElement {
  Paragraph = "PARAGRAPH",
  HeadingOne = "HEADING_ONE",
  HeadingTwo = "HEADING_TWO",
  BulletedList = "BULLETED_LIST",
  NumberedList = "NUMBERED_LIST",
  ListItem = "LIST_ITEM",
  Image = "IMAGE",
  Component = "COMPONENT"
}

export enum BodyPart {
  Neck = "NECK",
  Shoulders = "SHOULDERS",
  UpperBack = "UPPER_BACK",
  LowerBack = "LOWER_BACK",
  ArmsWristsHands = "ARMS_WRISTS_HANDS",
  HipsKneesLegs = "HIPS_KNEES_LEGS",
  AchillesAnklesFeet = "ACHILLES_ANKLES_FEET"
}

export enum BodyPosition {
  Standing = "STANDING",
  Seated = "SEATED",
  SideLying = "SIDE_LYING",
  AllFours = "ALL_FOURS",
  Kneeling = "KNEELING",
  Supine = "SUPINE",
  Prone = "PRONE"
}

export enum BreathworkConfigKey {
  FourSevenEight = "FOUR_SEVEN_EIGHT",
  Deep = "DEEP",
  Box = "BOX"
}

export type CompleteOnboardingData = {
  readonly __typename?: "CompleteOnboardingData";
  readonly user: User;
};

export type CompleteOnboardingPayload = {
  readonly email: Scalars["String"];
  readonly name: Scalars["String"];
  readonly phoneOs: PhoneOs;
  readonly painWhere: ReadonlyArray<Scalars["String"]>;
  readonly painHowLong: Scalars["String"];
  readonly country: Scalars["String"];
  readonly userData: Scalars["String"];
  readonly newsletterOptIn?: Maybe<Scalars["Boolean"]>;
};

export type CompleteOnboardingResponse = {
  readonly __typename?: "CompleteOnboardingResponse";
  readonly data?: Maybe<CompleteOnboardingData>;
  readonly error?: Maybe<Error>;
};

export type CompleteProgramFeedbackResponse = {
  readonly __typename?: "CompleteProgramFeedbackResponse";
  readonly programFeedback?: Maybe<ProgramFeedback>;
  readonly error?: Maybe<Error>;
};

export enum DailyAnchor {
  FirstThing = "FIRST_THING",
  BeforeBreakfast = "BEFORE_BREAKFAST",
  BeforeWork = "BEFORE_WORK",
  BeforeLunch = "BEFORE_LUNCH",
  AfterLunch = "AFTER_LUNCH",
  AfterWork = "AFTER_WORK",
  AfterDinner = "AFTER_DINNER",
  BeforeBed = "BEFORE_BED"
}

export type DocumentElement = {
  readonly __typename?: "DocumentElement";
  readonly children: ReadonlyArray<DocumentText>;
};

export type DocumentText = {
  readonly __typename?: "DocumentText";
  readonly underline?: Maybe<Scalars["Boolean"]>;
  readonly bold?: Maybe<Scalars["Boolean"]>;
  readonly italic?: Maybe<Scalars["Boolean"]>;
  readonly url?: Maybe<Scalars["String"]>;
  readonly text: Scalars["String"];
};

export enum EmailStatus {
  None = "NONE",
  Waitlist = "WAITLIST",
  Invited = "INVITED",
  Delinquent = "DELINQUENT",
  NewUser = "NEW_USER",
  ActiveUser = "ACTIVE_USER"
}

export enum EmployerContactType {
  Employer = "EMPLOYER",
  HealthPlan = "HEALTH_PLAN",
  Consultant = "CONSULTANT",
  Individual = "INDIVIDUAL",
  Media = "MEDIA",
  Investor = "INVESTOR",
  Vendor = "VENDOR"
}

export type EmployerWaitlistInput = {
  readonly email: Scalars["String"];
  readonly firstName: Scalars["String"];
  readonly lastName: Scalars["String"];
  readonly title: Scalars["String"];
  readonly contactType: EmployerContactType;
  readonly companyName: Scalars["String"];
  readonly isInterestedSoon: Scalars["Boolean"];
};

export enum Equipment {
  ComfortableClothes = "COMFORTABLE_CLOTHES",
  SoftGround = "SOFT_GROUND",
  Strap = "STRAP",
  SmallPillow = "SMALL_PILLOW"
}

export type Error = {
  readonly __typename?: "Error";
  readonly message: Scalars["String"];
  readonly code: Scalars["Int"];
};

export enum EvidenceScore {
  Promising = "PROMISING",
  Interesting = "INTERESTING",
  Unknown = "UNKNOWN",
  Bleak = "BLEAK"
}

export type Exercise = {
  readonly __typename?: "Exercise";
  readonly id: Scalars["String"];
  readonly name: Scalars["String"];
  readonly instructionText: Scalars["String"];
  readonly instructionTextAudioUrl?: Maybe<Scalars["String"]>;
  readonly instructionImage: File;
  readonly instructionImageUrl?: Maybe<Scalars["String"]>;
  readonly nameAudioUrl?: Maybe<Scalars["String"]>;
  readonly bodyParts: ReadonlyArray<BodyPart>;
  readonly position: BodyPosition;
  readonly purpose: ExercisePurpose;
  readonly isSided: Scalars["Boolean"];
  readonly equipment: ReadonlyArray<Equipment>;
};

export enum ExercisePurpose {
  Mobility = "MOBILITY",
  Strength = "STRENGTH",
  Relief = "RELIEF"
}

export type File = {
  readonly __typename?: "File";
  readonly name: Scalars["String"];
  readonly url: Scalars["String"];
};

export type GetAssignedTaskResponse = {
  readonly __typename?: "GetAssignedTaskResponse";
  readonly error?: Maybe<Error>;
  readonly assignedTask?: Maybe<AssignedTask>;
};

export type GetBaseTaskResponse = {
  readonly __typename?: "GetBaseTaskResponse";
  readonly error?: Maybe<Error>;
  readonly baseTask?: Maybe<BaseTask>;
};

export type GetTaskGroupResponse = {
  readonly __typename?: "GetTaskGroupResponse";
  readonly taskGroup?: Maybe<TaskGroup>;
  readonly error?: Maybe<Error>;
};

export type GetTemplateTaskGroupsResponse = {
  readonly __typename?: "GetTemplateTaskGroupsResponse";
  readonly templateTaskGroups: ReadonlyArray<TemplateTaskGroup>;
  readonly error?: Maybe<Error>;
};

export type GoalRecording = {
  readonly __typename?: "GoalRecording";
  readonly id: Scalars["String"];
  readonly painLevel: PainLevel;
  readonly notes?: Maybe<Scalars["String"]>;
  readonly completedAt: Scalars["DateTime"];
};

export type GoalRecordingResponse = {
  readonly __typename?: "GoalRecordingResponse";
  readonly goalRecording?: Maybe<GoalRecording>;
  readonly error?: Maybe<Error>;
};

export type GroupedTaskEvent = {
  readonly __typename?: "GroupedTaskEvent";
  readonly id: Scalars["String"];
  readonly title: Scalars["String"];
  readonly assignedTask: AssignedTask;
  readonly quantity: Scalars["Int"];
  readonly completedAt: Scalars["DateTime"];
};

export type ImageElement = {
  readonly __typename?: "ImageElement";
  readonly url: Scalars["String"];
};

export type JoinWaitlistV2Input = {
  readonly email: Scalars["String"];
  readonly name: Scalars["String"];
  readonly phoneOs: PhoneOs;
  readonly painWhere: ReadonlyArray<Scalars["String"]>;
  readonly painHowLong: Scalars["String"];
  readonly country: Scalars["String"];
  readonly userData: Scalars["String"];
  readonly newsletterOptIn?: Maybe<Scalars["Boolean"]>;
};

export type LoginResponse = {
  readonly __typename?: "LoginResponse";
  readonly sessionKey?: Maybe<Scalars["String"]>;
  readonly error?: Maybe<Scalars["String"]>;
};

export type Me = {
  readonly __typename?: "Me";
  readonly id: Scalars["Int"];
  readonly email: Scalars["String"];
  readonly fullName: Scalars["String"];
  readonly isAdmin: Scalars["Boolean"];
  readonly preferences: UserPreferences;
  readonly assignedModules: ReadonlyArray<AssignedModule>;
  readonly assignedPrograms: ReadonlyArray<AssignedProgram>;
  readonly createdAt: Scalars["DateTime"];
};

export type Module = {
  readonly __typename?: "Module";
  readonly id: Scalars["String"];
  readonly label: Scalars["String"];
  readonly title: Scalars["String"];
  readonly duration: Scalars["Int"];
  readonly type: ModuleType;
  readonly numAssignedModules: Scalars["Int"];
  readonly references: ReadonlyArray<ModuleReference>;
  readonly onboardingTriggers: ReadonlyArray<OnboardingTrigger>;
  readonly seriesModules: ReadonlyArray<SeriesModule>;
  readonly treatmentTriggers: ReadonlyArray<TreatmentTrigger>;
  readonly moduleSlides: ReadonlyArray<ModuleSlide>;
  readonly slides: ReadonlyArray<Slide>;
  readonly numSlides: Scalars["Int"];
  readonly templateTaskTriggers: ReadonlyArray<TemplateTaskTrigger>;
  readonly templateScheduleTaskTriggers: ReadonlyArray<
    TemplateScheduleTaskTrigger
  >;
  readonly templateUnscheduleTaskTriggers: ReadonlyArray<
    TemplateUnscheduleTaskTrigger
  >;
};

export type ModuleReference = {
  readonly __typename?: "ModuleReference";
  readonly id: Scalars["String"];
  readonly text: Scalars["String"];
  readonly url?: Maybe<Scalars["String"]>;
};

export type ModuleResponse = {
  readonly __typename?: "ModuleResponse";
  readonly module?: Maybe<Module>;
  readonly error?: Maybe<Error>;
};

export type ModuleSlide = {
  readonly __typename?: "ModuleSlide";
  readonly module: Module;
  readonly slide: Slide;
};

export type ModuleSlideInput = {
  readonly id: Scalars["String"];
  readonly type: SlideType;
  readonly text?: Maybe<Scalars["String"]>;
  readonly children?: Maybe<Scalars["String"]>;
  readonly content?: Maybe<Scalars["String"]>;
};

export enum ModuleType {
  Level_1 = "LEVEL_1",
  Level_2 = "LEVEL_2",
  Level_3 = "LEVEL_3",
  Level_4 = "LEVEL_4",
  Level_5 = "LEVEL_5",
  Level_6 = "LEVEL_6",
  Summary = "SUMMARY",
  Tutorial = "TUTORIAL",
  Lesson = "LESSON",
  Movement = "MOVEMENT",
  Mindfulness = "MINDFULNESS",
  MentalHealth = "MENTAL_HEALTH",
  Treatments = "TREATMENTS",
  PainCheckIn = "PAIN_CHECK_IN"
}

export type Mutation = {
  readonly __typename?: "Mutation";
  readonly completeAssignedModule: AssignedModule;
  readonly completeAssignedTaskEvent: AssignedTaskEventResponse;
  readonly completeBaseTaskEvent: AssignedTaskEventResponse;
  readonly completeOnboarding: CompleteOnboardingResponse;
  readonly completeProgramFeedback: CompleteProgramFeedbackResponse;
  readonly deleteAssignedTaskEvent: SuccessResponse;
  readonly deleteModule: SuccessResponse;
  readonly joinEmployerWaitlist: SuccessfulJoinEmployerWaitlistResponse;
  readonly joinProgram: AssignedProgramResponse;
  readonly joinWaitlistV2: SuccessfulJoinWaitlistResponse;
  readonly login: LoginResponse;
  readonly logout: SuccessResponse;
  readonly recordPainLevel: GoalRecordingResponse;
  readonly removeLocalAuthToken?: Maybe<Scalars["Boolean"]>;
  readonly reportFrontend404: SuccessResponse;
  readonly reportFrontendError: SuccessResponse;
  readonly reportMarketingError: SuccessResponse;
  readonly submitEmail: SubmitEmailResponse;
  readonly submitEmailCode: SubmitEmailCodeResponse;
  readonly toggleModuleFavorite: AssignedModule;
  readonly updateAvatar: User;
  readonly updateModule: Module;
  readonly updateModuleSlides: Module;
  readonly updatePlan: UserPlan;
  readonly updateTimezone: UpdateUserResponse;
};

export type MutationCompleteAssignedModuleArgs = {
  assignedProgramId: Scalars["String"];
  assignedModuleId: Scalars["String"];
};

export type MutationCompleteAssignedTaskEventArgs = {
  assignedProgramId: Scalars["String"];
  assignedTaskId: Scalars["String"];
  quantity: Scalars["Int"];
};

export type MutationCompleteBaseTaskEventArgs = {
  assignedProgramId: Scalars["String"];
  baseTaskId: Scalars["String"];
  quantity: Scalars["Int"];
  assignedTaskId: Maybe<Scalars["String"]>;
};

export type MutationCompleteOnboardingArgs = {
  payload: CompleteOnboardingPayload;
};

export type MutationCompleteProgramFeedbackArgs = {
  assignedProgramId: Scalars["String"];
  rating: Scalars["Int"];
  text: Scalars["String"];
};

export type MutationDeleteAssignedTaskEventArgs = {
  assignedProgramId: Scalars["String"];
  assignedTaskEventId: Scalars["String"];
};

export type MutationDeleteModuleArgs = {
  moduleId: Maybe<Scalars["String"]>;
};

export type MutationJoinEmployerWaitlistArgs = {
  payload: EmployerWaitlistInput;
};

export type MutationJoinProgramArgs = {
  programId: Scalars["String"];
};

export type MutationJoinWaitlistV2Args = {
  payload: JoinWaitlistV2Input;
};

export type MutationLoginArgs = {
  email: Scalars["String"];
  password: Scalars["String"];
};

export type MutationRecordPainLevelArgs = {
  assignedProgramId: Scalars["String"];
  painLevel: PainLevel;
  notes: Maybe<Scalars["String"]>;
};

export type MutationReportFrontend404Args = {
  payload: Maybe<Report404Input>;
};

export type MutationReportFrontendErrorArgs = {
  payload: ReportErrorInput;
};

export type MutationReportMarketingErrorArgs = {
  payload: Maybe<ReportErrorInput>;
};

export type MutationSubmitEmailArgs = {
  email: Scalars["String"];
};

export type MutationSubmitEmailCodeArgs = {
  email: Scalars["String"];
  code: Scalars["String"];
};

export type MutationToggleModuleFavoriteArgs = {
  assignedProgramId: Scalars["String"];
  assignedModuleId: Scalars["String"];
  isFavorite: Scalars["Boolean"];
};

export type MutationUpdateAvatarArgs = {
  avatar: Avatar;
};

export type MutationUpdateModuleArgs = {
  moduleId: Scalars["String"];
  payload: UpdateModulePayload;
};

export type MutationUpdateModuleSlidesArgs = {
  payload: UpdateModuleSlidesPayload;
};

export type MutationUpdatePlanArgs = {
  payload: Maybe<UpdatePlanPayload>;
};

export type MutationUpdateTimezoneArgs = {
  payload: UpdateTimezoneInput;
};

export type OnboardingTrigger = {
  readonly __typename?: "OnboardingTrigger";
  readonly key: OnboardingTriggerKey;
  readonly module: Module;
};

export type OnboardingTriggerInput = {
  readonly key: OnboardingTriggerKey;
};

export enum OnboardingTriggerKey {
  Progress = "PROGRESS",
  Scoreboard = "SCOREBOARD",
  Treatments = "TREATMENTS",
  Evidence = "EVIDENCE"
}

export type PaginatedGoalRecordings = {
  readonly __typename?: "PaginatedGoalRecordings";
  readonly objects: ReadonlyArray<GoalRecording>;
  readonly hasMore: Scalars["Boolean"];
};

export type PaginatedGroupedTaskEvents = {
  readonly __typename?: "PaginatedGroupedTaskEvents";
  readonly objects: ReadonlyArray<GroupedTaskEvent>;
  readonly hasMore: Scalars["Boolean"];
};

export enum PainLevel {
  Zero = "ZERO",
  One = "ONE",
  Two = "TWO",
  Three = "THREE",
  Four = "FOUR",
  Five = "FIVE",
  Six = "SIX",
  Seven = "SEVEN",
  Eight = "EIGHT",
  Nine = "NINE",
  Ten = "TEN"
}

export enum PhoneOs {
  Ios = "IOS",
  Android = "ANDROID",
  Other = "OTHER"
}

export type Program = {
  readonly __typename?: "Program";
  readonly id: Scalars["String"];
  readonly title: Scalars["String"];
  readonly createdAt: Scalars["DateTime"];
  readonly numUsers: Scalars["Int"];
  readonly programSeries: ReadonlyArray<ProgramSeries>;
};

export type ProgramFeedback = {
  readonly __typename?: "ProgramFeedback";
  readonly text: Scalars["String"];
  readonly rating: Scalars["Int"];
  readonly completedAt: Scalars["DateTime"];
};

export enum ProgramItemStatus {
  InProgress = "IN_PROGRESS",
  NotStarted = "NOT_STARTED",
  Complete = "COMPLETE"
}

export type ProgramResponse = {
  readonly __typename?: "ProgramResponse";
  readonly error?: Maybe<Error>;
  readonly program?: Maybe<Program>;
};

export type ProgramSeries = {
  readonly __typename?: "ProgramSeries";
  readonly series: Series;
};

export type Query = {
  readonly __typename?: "Query";
  readonly activePrograms: ReadonlyArray<Program>;
  readonly activeTaskSchedules: ReadonlyArray<TaskSchedule>;
  readonly assignedProgramStatus: AssignedProgramStatus;
  readonly assignedTasks: ReadonlyArray<AssignedTask>;
  readonly authToken?: Maybe<Scalars["String"]>;
  readonly favoriteModules: ReadonlyArray<AssignedModule>;
  readonly getAssignedModule: AssignedModuleResponse;
  readonly getAssignedProgram: AssignedProgramResponse;
  readonly getAssignedSeriesById: AssignedSeriesResponse;
  readonly getAssignedTask: GetAssignedTaskResponse;
  readonly getBaseTask: GetBaseTaskResponse;
  readonly getBaseTaskFromTemplateId: GetBaseTaskResponse;
  readonly getEmailStatus: SubmitEmailResponse;
  readonly getModule: ModuleResponse;
  readonly getModuleForUser: ModuleResponse;
  readonly getProgram: ProgramResponse;
  readonly getTaskGroup: GetTaskGroupResponse;
  readonly getTaskGroupFromTemplateId: GetTaskGroupResponse;
  readonly getTemplateTaskGroups: GetTemplateTaskGroupsResponse;
  readonly goalRecordings: PaginatedGoalRecordings;
  readonly groupedTaskEvents: PaginatedGroupedTaskEvents;
  readonly me: User;
  readonly programs: ReadonlyArray<Program>;
  readonly quote: Quote;
  readonly templateBaseTasks: ReadonlyArray<TemplateBaseTask>;
  readonly timezones: ReadonlyArray<Scalars["String"]>;
  readonly tomorrowQuote: Quote;
  readonly treatmentAreas: ReadonlyArray<Treatment>;
  readonly treatmentAreasAdmin: ReadonlyArray<Treatment>;
  readonly treatmentById: TreatmentResponse;
  readonly treatments: ReadonlyArray<Treatment>;
  readonly users: ReadonlyArray<User>;
};

export type QueryActiveTaskSchedulesArgs = {
  assignedProgramId: Scalars["String"];
};

export type QueryAssignedProgramStatusArgs = {
  assignedProgramId: Scalars["String"];
};

export type QueryAssignedTasksArgs = {
  assignedProgramId: Scalars["String"];
  date: Scalars["DateTime"];
};

export type QueryFavoriteModulesArgs = {
  assignedProgramId: Scalars["String"];
};

export type QueryGetAssignedModuleArgs = {
  assignedProgramId: Scalars["String"];
  assignedModuleId: Scalars["String"];
};

export type QueryGetAssignedProgramArgs = {
  assignedProgramId: Scalars["String"];
};

export type QueryGetAssignedSeriesByIdArgs = {
  assignedProgramId: Scalars["String"];
  assignedSeriesId: Scalars["String"];
};

export type QueryGetAssignedTaskArgs = {
  assignedProgramId: Scalars["String"];
  assignedTaskId: Scalars["String"];
};

export type QueryGetBaseTaskArgs = {
  assignedProgramId: Scalars["String"];
  baseTaskId: Maybe<Scalars["String"]>;
};

export type QueryGetBaseTaskFromTemplateIdArgs = {
  assignedProgramId: Scalars["String"];
  templateBaseTaskId: Maybe<Scalars["String"]>;
};

export type QueryGetEmailStatusArgs = {
  email: Scalars["String"];
};

export type QueryGetModuleArgs = {
  id: Maybe<Scalars["String"]>;
};

export type QueryGetModuleForUserArgs = {
  assignedProgramId: Scalars["String"];
  moduleId: Maybe<Scalars["String"]>;
};

export type QueryGetProgramArgs = {
  programId: Scalars["String"];
};

export type QueryGetTaskGroupArgs = {
  assignedProgramId: Scalars["String"];
  taskGroupId: Maybe<Scalars["String"]>;
};

export type QueryGetTaskGroupFromTemplateIdArgs = {
  assignedProgramId: Scalars["String"];
  templateTaskGroupId: Maybe<Scalars["String"]>;
};

export type QueryGetTemplateTaskGroupsArgs = {
  programId: Scalars["String"];
};

export type QueryGoalRecordingsArgs = {
  assignedProgramId: Scalars["String"];
  offset: Scalars["Int"];
  limit: Maybe<Scalars["Int"]>;
};

export type QueryGroupedTaskEventsArgs = {
  assignedProgramId: Scalars["String"];
  offset: Scalars["Int"];
  limit: Maybe<Scalars["Int"]>;
};

export type QueryQuoteArgs = {
  date: Scalars["DateTime"];
};

export type QueryTemplateBaseTasksArgs = {
  programId: Scalars["String"];
};

export type QueryTreatmentAreasArgs = {
  assignedProgramId: Scalars["String"];
};

export type QueryTreatmentAreasAdminArgs = {
  programId: Scalars["String"];
};

export type QueryTreatmentByIdArgs = {
  id: Scalars["String"];
  assignedProgramId: Scalars["String"];
};

export type Quote = {
  readonly __typename?: "Quote";
  readonly text: Scalars["String"];
  readonly attribution: Scalars["String"];
};

export type Recurrences = {
  readonly __typename?: "Recurrences";
  readonly rrules: ReadonlyArray<Rrule>;
};

export type Report404Input = {
  readonly pathname: Scalars["String"];
};

export type ReportErrorInput = {
  readonly componentName: Scalars["String"];
  readonly errorMessage: Scalars["String"];
  readonly stack?: Maybe<Scalars["String"]>;
};

export type Routine = {
  readonly __typename?: "Routine";
  readonly id: Scalars["String"];
  readonly description: Scalars["String"];
  readonly routineExercises: ReadonlyArray<RoutineExercise>;
};

export type RoutineExercise = {
  readonly __typename?: "RoutineExercise";
  readonly duration: Scalars["Int"];
  readonly exercise: Exercise;
  readonly sortOrder: Scalars["Int"];
  readonly audio?: Maybe<Scalars["String"]>;
};

export type Rrule = {
  readonly __typename?: "Rrule";
  readonly description: Scalars["String"];
};

export type ScheduleTaskTrigger = {
  readonly __typename?: "ScheduleTaskTrigger";
  readonly id: Scalars["String"];
  readonly baseTask: BaseTask;
  readonly module: Module;
  readonly quantity: Scalars["Int"];
  readonly recurrences: Recurrences;
  readonly startOffset: Scalars["Int"];
};

export type Series = {
  readonly __typename?: "Series";
  readonly id: Scalars["String"];
  readonly title: Scalars["String"];
  readonly subtitle?: Maybe<Scalars["String"]>;
  readonly iconKey?: Maybe<SeriesIconKey>;
  readonly children: ReadonlyArray<Series>;
  readonly seriesModules: ReadonlyArray<SeriesModule>;
};

export enum SeriesIconKey {
  Introduction = "INTRODUCTION",
  Novice = "NOVICE",
  Trainee = "TRAINEE",
  Practitioner = "PRACTITIONER",
  Pro = "PRO",
  Master = "MASTER"
}

export type SeriesModule = {
  readonly __typename?: "SeriesModule";
  readonly module: Module;
  readonly series: Series;
};

export type Slide = {
  readonly __typename?: "Slide";
  readonly id: Scalars["String"];
  readonly type: SlideType;
  readonly content?: Maybe<Scalars["String"]>;
};

export enum SlideElement {
  Paragraph = "PARAGRAPH",
  HeadingOne = "HEADING_ONE",
  HeadingTwo = "HEADING_TWO",
  BulletedList = "BULLETED_LIST",
  NumberedList = "NUMBERED_LIST",
  ListItem = "LIST_ITEM",
  Image = "IMAGE",
  Component = "COMPONENT"
}

export enum SlideType {
  Activity = "ACTIVITY",
  ChooseAvatar = "CHOOSE_AVATAR",
  RecordPain = "RECORD_PAIN",
  SetPlan = "SET_PLAN",
  ShouldRemind = "SHOULD_REMIND",
  Component = "COMPONENT",
  Document = "DOCUMENT",
  Takeaway = "TAKEAWAY"
}

export type SubmitEmailCodeResponse = {
  readonly __typename?: "SubmitEmailCodeResponse";
  readonly data?: Maybe<SuccessfulSubmitEmailCodeResponse>;
  readonly error?: Maybe<Error>;
};

export type SubmitEmailResponse = {
  readonly __typename?: "SubmitEmailResponse";
  readonly data?: Maybe<SuccessfulSubmitEmailResponse>;
  readonly error?: Maybe<Error>;
};

export type SuccessfulJoinEmployerWaitlistResponse = {
  readonly __typename?: "SuccessfulJoinEmployerWaitlistResponse";
  readonly success?: Maybe<Scalars["Boolean"]>;
  readonly error?: Maybe<Error>;
};

export type SuccessfulJoinWaitlistResponse = {
  readonly __typename?: "SuccessfulJoinWaitlistResponse";
  readonly success?: Maybe<Scalars["Boolean"]>;
  readonly error?: Maybe<Error>;
};

export type SuccessfulSubmitEmailCodeResponse = {
  readonly __typename?: "SuccessfulSubmitEmailCodeResponse";
  readonly me: User;
  readonly sessionKey: Scalars["String"];
};

export type SuccessfulSubmitEmailResponse = {
  readonly __typename?: "SuccessfulSubmitEmailResponse";
  readonly email: Scalars["String"];
  readonly status: EmailStatus;
  readonly prefersPassword: Scalars["Boolean"];
};

export type SuccessResponse = {
  readonly __typename?: "SuccessResponse";
  readonly success: Scalars["Boolean"];
  readonly error?: Maybe<Scalars["String"]>;
};

export type TaskEvidenceScore = {
  readonly __typename?: "TaskEvidenceScore";
  readonly score: EvidenceScore;
  readonly explanation: Scalars["String"];
};

export type TaskGroup = {
  readonly __typename?: "TaskGroup";
  readonly id: Scalars["String"];
  readonly iconKey: TaskIconKey;
  readonly title: Scalars["String"];
  readonly description: Scalars["String"];
  readonly baseTasks: ReadonlyArray<BaseTask>;
};

export enum TaskIconKey {
  All = "ALL",
  Education = "EDUCATION",
  ManualTherapy = "MANUAL_THERAPY",
  MentalHealth = "MENTAL_HEALTH",
  Movement = "MOVEMENT",
  CatchAThought = "CATCH_A_THOUGHT"
}

export enum TaskImplementationType {
  CompleteScheduledModules = "COMPLETE_SCHEDULED_MODULES",
  NotImplemented = "NOT_IMPLEMENTED",
  TaskGroup = "TASK_GROUP",
  Breathwork = "BREATHWORK",
  GuidedAudio = "GUIDED_AUDIO",
  ExerciseRoutine = "EXERCISE_ROUTINE",
  CatchAThought = "CATCH_A_THOUGHT",
  TaskSeries = "TASK_SERIES"
}

export type TaskSchedule = {
  readonly __typename?: "TaskSchedule";
  readonly id: Scalars["String"];
  readonly baseTask: BaseTask;
  readonly textualDescription: Scalars["String"];
};

export type TemplateBaseTask = {
  readonly __typename?: "TemplateBaseTask";
  readonly id: Scalars["String"];
  readonly title: Scalars["String"];
  readonly subtitle: Scalars["String"];
  readonly description: Scalars["String"];
  readonly iconKey: TaskIconKey;
};

export type TemplateBaseTasksInput = {
  readonly id: Scalars["String"];
};

export type TemplateScheduleTaskTrigger = {
  readonly __typename?: "TemplateScheduleTaskTrigger";
  readonly id: Scalars["String"];
  readonly templateBaseTask: TemplateBaseTask;
  readonly module: Module;
  readonly quantity: Scalars["Int"];
  readonly recurrences: Recurrences;
  readonly startOffset: Scalars["Int"];
};

export type TemplateTaskGroup = {
  readonly __typename?: "TemplateTaskGroup";
  readonly id: Scalars["String"];
  readonly iconKey: TaskIconKey;
  readonly title: Scalars["String"];
  readonly description: Scalars["String"];
  readonly templateBaseTasks: ReadonlyArray<TemplateBaseTask>;
};

export type TemplateTaskTrigger = {
  readonly __typename?: "TemplateTaskTrigger";
  readonly templateBaseTask: TemplateBaseTask;
  readonly module: Module;
};

export type TemplateUnscheduleTaskTrigger = {
  readonly __typename?: "TemplateUnscheduleTaskTrigger";
  readonly id: Scalars["String"];
  readonly templateBaseTask: TemplateBaseTask;
  readonly module: Module;
  readonly endOffset: Scalars["Int"];
};

export type Treatment = {
  readonly __typename?: "Treatment";
  readonly id: Scalars["String"];
  readonly name: Scalars["String"];
  readonly path: ReadonlyArray<Scalars["String"]>;
  readonly iconKey: TreatmentIconKey;
  readonly children: ReadonlyArray<Treatment>;
  readonly adminChildren: ReadonlyArray<Treatment>;
  readonly hasChildren: Scalars["Boolean"];
  readonly parent?: Maybe<Treatment>;
  readonly profile?: Maybe<TreatmentProfile>;
  readonly modules: ReadonlyArray<Module>;
};

export type TreatmentChildrenArgs = {
  assignedProgramId: Scalars["String"];
};

export enum TreatmentIconKey {
  Diet = "DIET",
  Education = "EDUCATION",
  Ergonomic = "ERGONOMIC",
  Exercise = "EXERCISE",
  Hardware = "HARDWARE",
  Lifestyle = "LIFESTYLE",
  ManualCare = "MANUAL_CARE",
  Medication = "MEDICATION",
  MentalHealth = "MENTAL_HEALTH",
  Mindfulness = "MINDFULNESS",
  Movement = "MOVEMENT",
  Other = "OTHER",
  PainEducation = "PAIN_EDUCATION",
  PersonalCare = "PERSONAL_CARE",
  PhysicalTherapy = "PHYSICAL_THERAPY",
  Sleep = "SLEEP",
  Surgery = "SURGERY",
  Treatments = "TREATMENTS"
}

export type TreatmentProfile = {
  readonly __typename?: "TreatmentProfile";
  readonly tagline?: Maybe<Scalars["String"]>;
  readonly description: Scalars["String"];
};

export type TreatmentResponse = {
  readonly __typename?: "TreatmentResponse";
  readonly error?: Maybe<Error>;
  readonly treatment?: Maybe<Treatment>;
};

export type TreatmentsInput = {
  readonly id: Scalars["String"];
};

export type TreatmentTrigger = {
  readonly __typename?: "TreatmentTrigger";
  readonly treatment: Treatment;
  readonly module: Module;
};

export type UnscheduleTaskTrigger = {
  readonly __typename?: "UnscheduleTaskTrigger";
  readonly id: Scalars["String"];
  readonly baseTask: BaseTask;
  readonly module: Module;
  readonly endOffset: Scalars["Int"];
};

export type UpdateModulePayload = {
  readonly title?: Maybe<Scalars["String"]>;
  readonly duration?: Maybe<Scalars["Int"]>;
  readonly type?: Maybe<ModuleType>;
  readonly onboardingTriggers?: Maybe<ReadonlyArray<OnboardingTriggerInput>>;
  readonly templateBaseTasks?: Maybe<ReadonlyArray<TemplateBaseTasksInput>>;
  readonly treatments?: Maybe<ReadonlyArray<TreatmentsInput>>;
};

export type UpdateModuleSlidesPayload = {
  readonly moduleId: Scalars["String"];
  readonly slides: ReadonlyArray<ModuleSlideInput>;
};

export type UpdatePlanPayload = {
  readonly anchor?: Maybe<DailyAnchor>;
  readonly time?: Maybe<Scalars["Time"]>;
  readonly shouldRemind?: Maybe<Scalars["Boolean"]>;
  readonly expoToken?: Maybe<Scalars["String"]>;
};

export type UpdateTimezoneInput = {
  readonly timezone: Scalars["String"];
};

export type UpdateUserResponse = {
  readonly __typename?: "UpdateUserResponse";
  readonly error?: Maybe<Error>;
  readonly user?: Maybe<User>;
};

export type User = {
  readonly __typename?: "User";
  readonly id: Scalars["Int"];
  readonly email: Scalars["String"];
  readonly fullName: Scalars["String"];
  readonly isAdmin: Scalars["Boolean"];
  readonly preferences?: Maybe<UserPreferences>;
  readonly timezone: Scalars["String"];
  readonly hasOffsetMismatch: Scalars["Boolean"];
  readonly hasOnboarded: Scalars["Boolean"];
  readonly assignedModules: ReadonlyArray<AssignedModule>;
  readonly assignedPrograms: ReadonlyArray<AssignedProgram>;
  readonly createdAt: Scalars["DateTime"];
  readonly emailStatus: EmailStatus;
  readonly phoneOs: PhoneOs;
  readonly painWhere: ReadonlyArray<Scalars["String"]>;
  readonly painHowLong: Scalars["String"];
  readonly country: Scalars["String"];
  readonly isDelinquent: Scalars["Boolean"];
  readonly plan?: Maybe<UserPlan>;
  readonly currentAssignedProgram?: Maybe<AssignedProgram>;
};

export type UserPlan = {
  readonly __typename?: "UserPlan";
  readonly anchor: DailyAnchor;
  readonly time: Scalars["Time"];
  readonly shouldRemind: Scalars["Boolean"];
  readonly expoToken?: Maybe<Scalars["String"]>;
};

export type UserPreferences = {
  readonly __typename?: "UserPreferences";
  readonly avatar: Avatar;
};

export type MeQueryVariables = Exact<{ [key: string]: never }>;

export type MeQuery = {
  readonly __typename?: "Query";
  readonly me: {
    readonly __typename?: "User";
    readonly email: string;
    readonly fullName: string;
    readonly isAdmin: boolean;
  };
};

export type LogoutMutationVariables = Exact<{ [key: string]: never }>;

export type LogoutMutation = {
  readonly __typename?: "Mutation";
  readonly logout: {
    readonly __typename?: "SuccessResponse";
    readonly success: boolean;
  };
};

export type LoginMutationVariables = Exact<{
  email: Scalars["String"];
  password: Scalars["String"];
}>;

export type LoginMutation = {
  readonly __typename?: "Mutation";
  readonly login: {
    readonly __typename?: "LoginResponse";
    readonly error?: Maybe<string>;
  };
};

export type ReportErrorMutationVariables = Exact<{
  payload: ReportErrorInput;
}>;

export type ReportErrorMutation = {
  readonly __typename?: "Mutation";
  readonly reportFrontendError: {
    readonly __typename?: "SuccessResponse";
    readonly success: boolean;
    readonly error?: Maybe<string>;
  };
};

export type ReportFrontend404MutationVariables = Exact<{
  payload: Report404Input;
}>;

export type ReportFrontend404Mutation = {
  readonly __typename?: "Mutation";
  readonly reportFrontend404: {
    readonly __typename?: "SuccessResponse";
    readonly success: boolean;
    readonly error?: Maybe<string>;
  };
};

export type ModulePartsFragment = {
  readonly __typename?: "Module";
  readonly id: string;
  readonly title: string;
  readonly type: ModuleType;
  readonly duration: number;
  readonly slides: ReadonlyArray<{
    readonly __typename?: "Slide";
    readonly id: string;
    readonly type: SlideType;
    readonly content?: Maybe<string>;
  }>;
};

export type UpdateModuleSlidesMutationVariables = Exact<{
  payload: UpdateModuleSlidesPayload;
}>;

export type UpdateModuleSlidesMutation = {
  readonly __typename?: "Mutation";
  readonly updateModuleSlides: {
    readonly __typename?: "Module";
  } & ModulePartsFragment;
};

export type GetTemplateTaskGroupsQueryVariables = Exact<{
  programId: Scalars["String"];
}>;

export type GetTemplateTaskGroupsQuery = {
  readonly __typename?: "Query";
  readonly getTemplateTaskGroups: {
    readonly __typename?: "GetTemplateTaskGroupsResponse";
    readonly templateTaskGroups: ReadonlyArray<{
      readonly __typename?: "TemplateTaskGroup";
      readonly id: string;
      readonly title: string;
      readonly templateBaseTasks: ReadonlyArray<{
        readonly __typename?: "TemplateBaseTask";
        readonly id: string;
        readonly title: string;
      }>;
    }>;
  };
};

export type TreatmentAttrsFragment = {
  readonly __typename?: "Treatment";
  readonly id: string;
  readonly name: string;
  readonly modules: ReadonlyArray<{
    readonly __typename?: "Module";
    readonly id: string;
    readonly title: string;
    readonly slides: ReadonlyArray<{
      readonly __typename?: "Slide";
      readonly id: string;
      readonly type: SlideType;
      readonly content?: Maybe<string>;
    }>;
  }>;
};

export type TreatmentAttrsRecursiveFragment = {
  readonly __typename?: "Treatment";
  readonly adminChildren: ReadonlyArray<
    {
      readonly __typename?: "Treatment";
      readonly adminChildren: ReadonlyArray<
        {
          readonly __typename?: "Treatment";
          readonly adminChildren: ReadonlyArray<
            {
              readonly __typename?: "Treatment";
              readonly adminChildren: ReadonlyArray<
                {
                  readonly __typename?: "Treatment";
                  readonly adminChildren: ReadonlyArray<
                    {
                      readonly __typename?: "Treatment";
                    } & TreatmentAttrsFragment
                  >;
                } & TreatmentAttrsFragment
              >;
            } & TreatmentAttrsFragment
          >;
        } & TreatmentAttrsFragment
      >;
    } & TreatmentAttrsFragment
  >;
} & TreatmentAttrsFragment;

export type AdminTreatmentAreasQueryVariables = Exact<{
  programId: Scalars["String"];
}>;

export type AdminTreatmentAreasQuery = {
  readonly __typename?: "Query";
  readonly treatmentAreasAdmin: ReadonlyArray<
    { readonly __typename?: "Treatment" } & TreatmentAttrsRecursiveFragment
  >;
};

export type UpdateModuleMutationVariables = Exact<{
  moduleId: Scalars["String"];
  payload: UpdateModulePayload;
}>;

export type UpdateModuleMutation = {
  readonly __typename?: "Mutation";
  readonly updateModule: {
    readonly __typename?: "Module";
  } & EditModulePanelFragment;
};

export type ModulePreDeleteDetailsQueryVariables = Exact<{
  id: Scalars["String"];
}>;

export type ModulePreDeleteDetailsQuery = {
  readonly __typename?: "Query";
  readonly getModule: {
    readonly __typename?: "ModuleResponse";
    readonly module?: Maybe<{
      readonly __typename?: "Module";
      readonly numAssignedModules: number;
      readonly numSlides: number;
    }>;
    readonly error?: Maybe<{
      readonly __typename?: "Error";
      readonly message: string;
    }>;
  };
};

export type DeleteModuleMutationVariables = Exact<{
  moduleId: Scalars["String"];
}>;

export type DeleteModuleMutation = {
  readonly __typename?: "Mutation";
  readonly deleteModule: {
    readonly __typename?: "SuccessResponse";
    readonly success: boolean;
  };
};

export type TreatmentsQueryVariables = Exact<{ [key: string]: never }>;

export type TreatmentsQuery = {
  readonly __typename?: "Query";
  readonly treatments: ReadonlyArray<{
    readonly __typename?: "Treatment";
    readonly id: string;
    readonly name: string;
  }>;
};

export type TemplateBaseTasksQueryVariables = Exact<{
  programId: Scalars["String"];
}>;

export type TemplateBaseTasksQuery = {
  readonly __typename?: "Query";
  readonly templateBaseTasks: ReadonlyArray<{
    readonly __typename?: "TemplateBaseTask";
    readonly id: string;
    readonly title: string;
  }>;
};

export type EditModulePanelFragment = {
  readonly __typename?: "Module";
  readonly id: string;
  readonly title: string;
  readonly duration: number;
  readonly type: ModuleType;
  readonly onboardingTriggers: ReadonlyArray<{
    readonly __typename?: "OnboardingTrigger";
    readonly key: OnboardingTriggerKey;
  }>;
  readonly templateScheduleTaskTriggers: ReadonlyArray<{
    readonly __typename?: "TemplateScheduleTaskTrigger";
    readonly id: string;
    readonly startOffset: number;
    readonly templateBaseTask: {
      readonly __typename?: "TemplateBaseTask";
      readonly id: string;
      readonly title: string;
    };
    readonly recurrences: {
      readonly __typename?: "Recurrences";
      readonly rrules: ReadonlyArray<{
        readonly __typename?: "Rrule";
        readonly description: string;
      }>;
    };
  }>;
  readonly templateUnscheduleTaskTriggers: ReadonlyArray<{
    readonly __typename?: "TemplateUnscheduleTaskTrigger";
    readonly id: string;
    readonly endOffset: number;
    readonly templateBaseTask: {
      readonly __typename?: "TemplateBaseTask";
      readonly id: string;
      readonly title: string;
    };
  }>;
  readonly templateTaskTriggers: ReadonlyArray<{
    readonly __typename?: "TemplateTaskTrigger";
    readonly templateBaseTask: {
      readonly __typename?: "TemplateBaseTask";
      readonly id: string;
      readonly title: string;
    };
  }>;
  readonly treatmentTriggers: ReadonlyArray<{
    readonly __typename?: "TreatmentTrigger";
    readonly treatment: {
      readonly __typename?: "Treatment";
      readonly id: string;
      readonly name: string;
    };
  }>;
};

export type GetModuleQueryVariables = Exact<{
  id: Scalars["String"];
}>;

export type GetModuleQuery = {
  readonly __typename?: "Query";
  readonly getModule: {
    readonly __typename?: "ModuleResponse";
    readonly module?: Maybe<
      { readonly __typename?: "Module" } & EditSlidesModalFragment &
        EditModulePanelFragment
    >;
  };
};

export type UsersListQueryVariables = Exact<{ [key: string]: never }>;

export type UsersListQuery = {
  readonly __typename?: "Query";
  readonly users: ReadonlyArray<
    { readonly __typename?: "User" } & UserRowFragment
  >;
};

export type ProgramRowFragment = {
  readonly __typename?: "Program";
  readonly id: string;
  readonly title: string;
  readonly numUsers: number;
  readonly createdAt: any;
};

export type ProgramListQueryVariables = Exact<{ [key: string]: never }>;

export type ProgramListQuery = {
  readonly __typename?: "Query";
  readonly programs: ReadonlyArray<
    { readonly __typename?: "Program" } & ProgramRowFragment
  >;
};

export type EditSlidesModalFragment = {
  readonly __typename?: "Module";
  readonly id: string;
  readonly slides: ReadonlyArray<{
    readonly __typename?: "Slide";
    readonly id: string;
    readonly type: SlideType;
    readonly content?: Maybe<string>;
  }>;
};

export type UserRowFragment = {
  readonly __typename?: "User";
  readonly id: number;
  readonly fullName: string;
  readonly email: string;
  readonly createdAt: any;
  readonly assignedPrograms: ReadonlyArray<{
    readonly __typename?: "AssignedProgram";
    readonly id: string;
  }>;
};

export type SeriesAttrsFragment = {
  readonly __typename?: "Series";
  readonly id: string;
  readonly title: string;
  readonly subtitle?: Maybe<string>;
  readonly iconKey?: Maybe<SeriesIconKey>;
  readonly seriesModules: ReadonlyArray<{
    readonly __typename?: "SeriesModule";
    readonly module: {
      readonly __typename?: "Module";
      readonly id: string;
      readonly title: string;
    };
  }>;
};

export type SeriesAttrsRecursiveFragment = {
  readonly __typename?: "Series";
  readonly children: ReadonlyArray<
    {
      readonly __typename?: "Series";
      readonly children: ReadonlyArray<
        {
          readonly __typename?: "Series";
          readonly children: ReadonlyArray<
            {
              readonly __typename?: "Series";
              readonly children: ReadonlyArray<
                {
                  readonly __typename?: "Series";
                  readonly children: ReadonlyArray<
                    { readonly __typename?: "Series" } & SeriesAttrsFragment
                  >;
                } & SeriesAttrsFragment
              >;
            } & SeriesAttrsFragment
          >;
        } & SeriesAttrsFragment
      >;
    } & SeriesAttrsFragment
  >;
} & SeriesAttrsFragment;

export type GetProgramQueryVariables = Exact<{
  programId: Scalars["String"];
}>;

export type GetProgramQuery = {
  readonly __typename?: "Query";
  readonly getProgram: {
    readonly __typename?: "ProgramResponse";
    readonly program?: Maybe<{
      readonly __typename?: "Program";
      readonly title: string;
      readonly programSeries: ReadonlyArray<{
        readonly __typename?: "ProgramSeries";
        readonly series: {
          readonly __typename?: "Series";
        } & SeriesAttrsRecursiveFragment;
      }>;
    }>;
  };
};

export const ModulePartsFragmentDoc = gql`
  fragment ModuleParts on Module {
    id
    title
    type
    duration
    slides {
      id
      type
      content
    }
  }
`;
export const TreatmentAttrsFragmentDoc = gql`
  fragment TreatmentAttrs on Treatment {
    id
    name
    modules {
      id
      title
      slides {
        id
        type
        content
      }
    }
  }
`;
export const TreatmentAttrsRecursiveFragmentDoc = gql`
  fragment TreatmentAttrsRecursive on Treatment {
    ...TreatmentAttrs
    adminChildren {
      ...TreatmentAttrs
      adminChildren {
        ...TreatmentAttrs
        adminChildren {
          ...TreatmentAttrs
          adminChildren {
            ...TreatmentAttrs
            adminChildren {
              ...TreatmentAttrs
            }
          }
        }
      }
    }
  }
  ${TreatmentAttrsFragmentDoc}
`;
export const EditModulePanelFragmentDoc = gql`
  fragment EditModulePanel on Module {
    id
    title
    duration
    type
    onboardingTriggers {
      key
    }
    templateScheduleTaskTriggers {
      id
      templateBaseTask {
        id
        title
      }
      startOffset
      recurrences {
        rrules {
          description
        }
      }
    }
    templateUnscheduleTaskTriggers {
      id
      templateBaseTask {
        id
        title
      }
      endOffset
    }
    templateTaskTriggers {
      templateBaseTask {
        id
        title
      }
    }
    treatmentTriggers {
      treatment {
        id
        name
      }
    }
  }
`;
export const ProgramRowFragmentDoc = gql`
  fragment ProgramRow on Program {
    id
    title
    numUsers
    createdAt
  }
`;
export const EditSlidesModalFragmentDoc = gql`
  fragment EditSlidesModal on Module {
    id
    slides {
      id
      type
      content
    }
  }
`;
export const UserRowFragmentDoc = gql`
  fragment UserRow on User {
    id
    fullName
    email
    assignedPrograms {
      id
    }
    createdAt
  }
`;
export const SeriesAttrsFragmentDoc = gql`
  fragment SeriesAttrs on Series {
    id
    title
    subtitle
    iconKey
    seriesModules {
      module {
        id
        title
      }
    }
  }
`;
export const SeriesAttrsRecursiveFragmentDoc = gql`
  fragment SeriesAttrsRecursive on Series {
    ...SeriesAttrs
    children {
      ...SeriesAttrs
      children {
        ...SeriesAttrs
        children {
          ...SeriesAttrs
          children {
            ...SeriesAttrs
            children {
              ...SeriesAttrs
            }
          }
        }
      }
    }
  }
  ${SeriesAttrsFragmentDoc}
`;
export const MeDocument = gql`
  query Me {
    me {
      email
      fullName
      isAdmin
    }
  }
`;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<MeQuery, MeQueryVariables>
) {
  return ApolloReactHooks.useQuery<MeQuery, MeQueryVariables>(
    MeDocument,
    baseOptions
  );
}
export function useMeLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MeQuery, MeQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<MeQuery, MeQueryVariables>(
    MeDocument,
    baseOptions
  );
}
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = ApolloReactCommon.QueryResult<
  MeQuery,
  MeQueryVariables
>;
export const LogoutDocument = gql`
  mutation Logout {
    logout {
      success
    }
  }
`;
export type LogoutMutationFn = ApolloReactCommon.MutationFunction<
  LogoutMutation,
  LogoutMutationVariables
>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    LogoutMutation,
    LogoutMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<LogoutMutation, LogoutMutationVariables>(
    LogoutDocument,
    baseOptions
  );
}
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = ApolloReactCommon.MutationResult<
  LogoutMutation
>;
export type LogoutMutationOptions = ApolloReactCommon.BaseMutationOptions<
  LogoutMutation,
  LogoutMutationVariables
>;
export const LoginDocument = gql`
  mutation Login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      error
    }
  }
`;
export type LoginMutationFn = ApolloReactCommon.MutationFunction<
  LoginMutation,
  LoginMutationVariables
>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    LoginMutation,
    LoginMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<LoginMutation, LoginMutationVariables>(
    LoginDocument,
    baseOptions
  );
}
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = ApolloReactCommon.MutationResult<
  LoginMutation
>;
export type LoginMutationOptions = ApolloReactCommon.BaseMutationOptions<
  LoginMutation,
  LoginMutationVariables
>;
export const ReportErrorDocument = gql`
  mutation ReportError($payload: ReportErrorInput!) {
    reportFrontendError(payload: $payload) {
      success
      error
    }
  }
`;
export type ReportErrorMutationFn = ApolloReactCommon.MutationFunction<
  ReportErrorMutation,
  ReportErrorMutationVariables
>;

/**
 * __useReportErrorMutation__
 *
 * To run a mutation, you first call `useReportErrorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReportErrorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reportErrorMutation, { data, loading, error }] = useReportErrorMutation({
 *   variables: {
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useReportErrorMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ReportErrorMutation,
    ReportErrorMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    ReportErrorMutation,
    ReportErrorMutationVariables
  >(ReportErrorDocument, baseOptions);
}
export type ReportErrorMutationHookResult = ReturnType<
  typeof useReportErrorMutation
>;
export type ReportErrorMutationResult = ApolloReactCommon.MutationResult<
  ReportErrorMutation
>;
export type ReportErrorMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ReportErrorMutation,
  ReportErrorMutationVariables
>;
export const ReportFrontend404Document = gql`
  mutation ReportFrontend404($payload: Report404Input!) {
    reportFrontend404(payload: $payload) {
      success
      error
    }
  }
`;
export type ReportFrontend404MutationFn = ApolloReactCommon.MutationFunction<
  ReportFrontend404Mutation,
  ReportFrontend404MutationVariables
>;

/**
 * __useReportFrontend404Mutation__
 *
 * To run a mutation, you first call `useReportFrontend404Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReportFrontend404Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reportFrontend404Mutation, { data, loading, error }] = useReportFrontend404Mutation({
 *   variables: {
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useReportFrontend404Mutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ReportFrontend404Mutation,
    ReportFrontend404MutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    ReportFrontend404Mutation,
    ReportFrontend404MutationVariables
  >(ReportFrontend404Document, baseOptions);
}
export type ReportFrontend404MutationHookResult = ReturnType<
  typeof useReportFrontend404Mutation
>;
export type ReportFrontend404MutationResult = ApolloReactCommon.MutationResult<
  ReportFrontend404Mutation
>;
export type ReportFrontend404MutationOptions = ApolloReactCommon.BaseMutationOptions<
  ReportFrontend404Mutation,
  ReportFrontend404MutationVariables
>;
export const UpdateModuleSlidesDocument = gql`
  mutation UpdateModuleSlides($payload: UpdateModuleSlidesPayload!) {
    updateModuleSlides(payload: $payload) {
      ...ModuleParts
    }
  }
  ${ModulePartsFragmentDoc}
`;
export type UpdateModuleSlidesMutationFn = ApolloReactCommon.MutationFunction<
  UpdateModuleSlidesMutation,
  UpdateModuleSlidesMutationVariables
>;

/**
 * __useUpdateModuleSlidesMutation__
 *
 * To run a mutation, you first call `useUpdateModuleSlidesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateModuleSlidesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateModuleSlidesMutation, { data, loading, error }] = useUpdateModuleSlidesMutation({
 *   variables: {
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useUpdateModuleSlidesMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateModuleSlidesMutation,
    UpdateModuleSlidesMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateModuleSlidesMutation,
    UpdateModuleSlidesMutationVariables
  >(UpdateModuleSlidesDocument, baseOptions);
}
export type UpdateModuleSlidesMutationHookResult = ReturnType<
  typeof useUpdateModuleSlidesMutation
>;
export type UpdateModuleSlidesMutationResult = ApolloReactCommon.MutationResult<
  UpdateModuleSlidesMutation
>;
export type UpdateModuleSlidesMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateModuleSlidesMutation,
  UpdateModuleSlidesMutationVariables
>;
export const GetTemplateTaskGroupsDocument = gql`
  query GetTemplateTaskGroups($programId: String!) {
    getTemplateTaskGroups(programId: $programId) {
      templateTaskGroups {
        id
        title
        templateBaseTasks {
          id
          title
        }
      }
    }
  }
`;

/**
 * __useGetTemplateTaskGroupsQuery__
 *
 * To run a query within a React component, call `useGetTemplateTaskGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTemplateTaskGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTemplateTaskGroupsQuery({
 *   variables: {
 *      programId: // value for 'programId'
 *   },
 * });
 */
export function useGetTemplateTaskGroupsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetTemplateTaskGroupsQuery,
    GetTemplateTaskGroupsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetTemplateTaskGroupsQuery,
    GetTemplateTaskGroupsQueryVariables
  >(GetTemplateTaskGroupsDocument, baseOptions);
}
export function useGetTemplateTaskGroupsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetTemplateTaskGroupsQuery,
    GetTemplateTaskGroupsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetTemplateTaskGroupsQuery,
    GetTemplateTaskGroupsQueryVariables
  >(GetTemplateTaskGroupsDocument, baseOptions);
}
export type GetTemplateTaskGroupsQueryHookResult = ReturnType<
  typeof useGetTemplateTaskGroupsQuery
>;
export type GetTemplateTaskGroupsLazyQueryHookResult = ReturnType<
  typeof useGetTemplateTaskGroupsLazyQuery
>;
export type GetTemplateTaskGroupsQueryResult = ApolloReactCommon.QueryResult<
  GetTemplateTaskGroupsQuery,
  GetTemplateTaskGroupsQueryVariables
>;
export const AdminTreatmentAreasDocument = gql`
  query AdminTreatmentAreas($programId: String!) {
    treatmentAreasAdmin(programId: $programId) {
      ...TreatmentAttrsRecursive
    }
  }
  ${TreatmentAttrsRecursiveFragmentDoc}
`;

/**
 * __useAdminTreatmentAreasQuery__
 *
 * To run a query within a React component, call `useAdminTreatmentAreasQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminTreatmentAreasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminTreatmentAreasQuery({
 *   variables: {
 *      programId: // value for 'programId'
 *   },
 * });
 */
export function useAdminTreatmentAreasQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    AdminTreatmentAreasQuery,
    AdminTreatmentAreasQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    AdminTreatmentAreasQuery,
    AdminTreatmentAreasQueryVariables
  >(AdminTreatmentAreasDocument, baseOptions);
}
export function useAdminTreatmentAreasLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    AdminTreatmentAreasQuery,
    AdminTreatmentAreasQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    AdminTreatmentAreasQuery,
    AdminTreatmentAreasQueryVariables
  >(AdminTreatmentAreasDocument, baseOptions);
}
export type AdminTreatmentAreasQueryHookResult = ReturnType<
  typeof useAdminTreatmentAreasQuery
>;
export type AdminTreatmentAreasLazyQueryHookResult = ReturnType<
  typeof useAdminTreatmentAreasLazyQuery
>;
export type AdminTreatmentAreasQueryResult = ApolloReactCommon.QueryResult<
  AdminTreatmentAreasQuery,
  AdminTreatmentAreasQueryVariables
>;
export const UpdateModuleDocument = gql`
  mutation UpdateModule($moduleId: String!, $payload: UpdateModulePayload!) {
    updateModule(moduleId: $moduleId, payload: $payload) {
      ...EditModulePanel
    }
  }
  ${EditModulePanelFragmentDoc}
`;
export type UpdateModuleMutationFn = ApolloReactCommon.MutationFunction<
  UpdateModuleMutation,
  UpdateModuleMutationVariables
>;

/**
 * __useUpdateModuleMutation__
 *
 * To run a mutation, you first call `useUpdateModuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateModuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateModuleMutation, { data, loading, error }] = useUpdateModuleMutation({
 *   variables: {
 *      moduleId: // value for 'moduleId'
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useUpdateModuleMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateModuleMutation,
    UpdateModuleMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateModuleMutation,
    UpdateModuleMutationVariables
  >(UpdateModuleDocument, baseOptions);
}
export type UpdateModuleMutationHookResult = ReturnType<
  typeof useUpdateModuleMutation
>;
export type UpdateModuleMutationResult = ApolloReactCommon.MutationResult<
  UpdateModuleMutation
>;
export type UpdateModuleMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateModuleMutation,
  UpdateModuleMutationVariables
>;
export const ModulePreDeleteDetailsDocument = gql`
  query ModulePreDeleteDetails($id: String!) {
    getModule(id: $id) {
      module {
        numAssignedModules
        numSlides
      }
      error {
        message
      }
    }
  }
`;

/**
 * __useModulePreDeleteDetailsQuery__
 *
 * To run a query within a React component, call `useModulePreDeleteDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useModulePreDeleteDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useModulePreDeleteDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useModulePreDeleteDetailsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ModulePreDeleteDetailsQuery,
    ModulePreDeleteDetailsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    ModulePreDeleteDetailsQuery,
    ModulePreDeleteDetailsQueryVariables
  >(ModulePreDeleteDetailsDocument, baseOptions);
}
export function useModulePreDeleteDetailsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ModulePreDeleteDetailsQuery,
    ModulePreDeleteDetailsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    ModulePreDeleteDetailsQuery,
    ModulePreDeleteDetailsQueryVariables
  >(ModulePreDeleteDetailsDocument, baseOptions);
}
export type ModulePreDeleteDetailsQueryHookResult = ReturnType<
  typeof useModulePreDeleteDetailsQuery
>;
export type ModulePreDeleteDetailsLazyQueryHookResult = ReturnType<
  typeof useModulePreDeleteDetailsLazyQuery
>;
export type ModulePreDeleteDetailsQueryResult = ApolloReactCommon.QueryResult<
  ModulePreDeleteDetailsQuery,
  ModulePreDeleteDetailsQueryVariables
>;
export const DeleteModuleDocument = gql`
  mutation DeleteModule($moduleId: String!) {
    deleteModule(moduleId: $moduleId) {
      success
    }
  }
`;
export type DeleteModuleMutationFn = ApolloReactCommon.MutationFunction<
  DeleteModuleMutation,
  DeleteModuleMutationVariables
>;

/**
 * __useDeleteModuleMutation__
 *
 * To run a mutation, you first call `useDeleteModuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteModuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteModuleMutation, { data, loading, error }] = useDeleteModuleMutation({
 *   variables: {
 *      moduleId: // value for 'moduleId'
 *   },
 * });
 */
export function useDeleteModuleMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteModuleMutation,
    DeleteModuleMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    DeleteModuleMutation,
    DeleteModuleMutationVariables
  >(DeleteModuleDocument, baseOptions);
}
export type DeleteModuleMutationHookResult = ReturnType<
  typeof useDeleteModuleMutation
>;
export type DeleteModuleMutationResult = ApolloReactCommon.MutationResult<
  DeleteModuleMutation
>;
export type DeleteModuleMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteModuleMutation,
  DeleteModuleMutationVariables
>;
export const TreatmentsDocument = gql`
  query Treatments {
    treatments {
      id
      name
    }
  }
`;

/**
 * __useTreatmentsQuery__
 *
 * To run a query within a React component, call `useTreatmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTreatmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTreatmentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useTreatmentsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    TreatmentsQuery,
    TreatmentsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<TreatmentsQuery, TreatmentsQueryVariables>(
    TreatmentsDocument,
    baseOptions
  );
}
export function useTreatmentsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    TreatmentsQuery,
    TreatmentsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    TreatmentsQuery,
    TreatmentsQueryVariables
  >(TreatmentsDocument, baseOptions);
}
export type TreatmentsQueryHookResult = ReturnType<typeof useTreatmentsQuery>;
export type TreatmentsLazyQueryHookResult = ReturnType<
  typeof useTreatmentsLazyQuery
>;
export type TreatmentsQueryResult = ApolloReactCommon.QueryResult<
  TreatmentsQuery,
  TreatmentsQueryVariables
>;
export const TemplateBaseTasksDocument = gql`
  query TemplateBaseTasks($programId: String!) {
    templateBaseTasks(programId: $programId) {
      id
      title
    }
  }
`;

/**
 * __useTemplateBaseTasksQuery__
 *
 * To run a query within a React component, call `useTemplateBaseTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useTemplateBaseTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTemplateBaseTasksQuery({
 *   variables: {
 *      programId: // value for 'programId'
 *   },
 * });
 */
export function useTemplateBaseTasksQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    TemplateBaseTasksQuery,
    TemplateBaseTasksQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    TemplateBaseTasksQuery,
    TemplateBaseTasksQueryVariables
  >(TemplateBaseTasksDocument, baseOptions);
}
export function useTemplateBaseTasksLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    TemplateBaseTasksQuery,
    TemplateBaseTasksQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    TemplateBaseTasksQuery,
    TemplateBaseTasksQueryVariables
  >(TemplateBaseTasksDocument, baseOptions);
}
export type TemplateBaseTasksQueryHookResult = ReturnType<
  typeof useTemplateBaseTasksQuery
>;
export type TemplateBaseTasksLazyQueryHookResult = ReturnType<
  typeof useTemplateBaseTasksLazyQuery
>;
export type TemplateBaseTasksQueryResult = ApolloReactCommon.QueryResult<
  TemplateBaseTasksQuery,
  TemplateBaseTasksQueryVariables
>;
export const GetModuleDocument = gql`
  query GetModule($id: String!) {
    getModule(id: $id) {
      module {
        ...EditSlidesModal
        ...EditModulePanel
      }
    }
  }
  ${EditSlidesModalFragmentDoc}
  ${EditModulePanelFragmentDoc}
`;

/**
 * __useGetModuleQuery__
 *
 * To run a query within a React component, call `useGetModuleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetModuleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetModuleQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetModuleQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetModuleQuery,
    GetModuleQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<GetModuleQuery, GetModuleQueryVariables>(
    GetModuleDocument,
    baseOptions
  );
}
export function useGetModuleLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetModuleQuery,
    GetModuleQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<GetModuleQuery, GetModuleQueryVariables>(
    GetModuleDocument,
    baseOptions
  );
}
export type GetModuleQueryHookResult = ReturnType<typeof useGetModuleQuery>;
export type GetModuleLazyQueryHookResult = ReturnType<
  typeof useGetModuleLazyQuery
>;
export type GetModuleQueryResult = ApolloReactCommon.QueryResult<
  GetModuleQuery,
  GetModuleQueryVariables
>;
export const UsersListDocument = gql`
  query UsersList {
    users {
      ...UserRow
    }
  }
  ${UserRowFragmentDoc}
`;

/**
 * __useUsersListQuery__
 *
 * To run a query within a React component, call `useUsersListQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersListQuery({
 *   variables: {
 *   },
 * });
 */
export function useUsersListQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    UsersListQuery,
    UsersListQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<UsersListQuery, UsersListQueryVariables>(
    UsersListDocument,
    baseOptions
  );
}
export function useUsersListLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    UsersListQuery,
    UsersListQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<UsersListQuery, UsersListQueryVariables>(
    UsersListDocument,
    baseOptions
  );
}
export type UsersListQueryHookResult = ReturnType<typeof useUsersListQuery>;
export type UsersListLazyQueryHookResult = ReturnType<
  typeof useUsersListLazyQuery
>;
export type UsersListQueryResult = ApolloReactCommon.QueryResult<
  UsersListQuery,
  UsersListQueryVariables
>;
export const ProgramListDocument = gql`
  query ProgramList {
    programs {
      ...ProgramRow
    }
  }
  ${ProgramRowFragmentDoc}
`;

/**
 * __useProgramListQuery__
 *
 * To run a query within a React component, call `useProgramListQuery` and pass it any options that fit your needs.
 * When your component renders, `useProgramListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProgramListQuery({
 *   variables: {
 *   },
 * });
 */
export function useProgramListQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ProgramListQuery,
    ProgramListQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<ProgramListQuery, ProgramListQueryVariables>(
    ProgramListDocument,
    baseOptions
  );
}
export function useProgramListLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ProgramListQuery,
    ProgramListQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    ProgramListQuery,
    ProgramListQueryVariables
  >(ProgramListDocument, baseOptions);
}
export type ProgramListQueryHookResult = ReturnType<typeof useProgramListQuery>;
export type ProgramListLazyQueryHookResult = ReturnType<
  typeof useProgramListLazyQuery
>;
export type ProgramListQueryResult = ApolloReactCommon.QueryResult<
  ProgramListQuery,
  ProgramListQueryVariables
>;
export const GetProgramDocument = gql`
  query GetProgram($programId: String!) {
    getProgram(programId: $programId) {
      program {
        title
        programSeries {
          series {
            ...SeriesAttrsRecursive
          }
        }
      }
    }
  }
  ${SeriesAttrsRecursiveFragmentDoc}
`;

/**
 * __useGetProgramQuery__
 *
 * To run a query within a React component, call `useGetProgramQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProgramQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProgramQuery({
 *   variables: {
 *      programId: // value for 'programId'
 *   },
 * });
 */
export function useGetProgramQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetProgramQuery,
    GetProgramQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<GetProgramQuery, GetProgramQueryVariables>(
    GetProgramDocument,
    baseOptions
  );
}
export function useGetProgramLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetProgramQuery,
    GetProgramQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetProgramQuery,
    GetProgramQueryVariables
  >(GetProgramDocument, baseOptions);
}
export type GetProgramQueryHookResult = ReturnType<typeof useGetProgramQuery>;
export type GetProgramLazyQueryHookResult = ReturnType<
  typeof useGetProgramLazyQuery
>;
export type GetProgramQueryResult = ApolloReactCommon.QueryResult<
  GetProgramQuery,
  GetProgramQueryVariables
>;
