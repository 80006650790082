import { Transforms } from "slate";
import { ReactEditor } from "slate-react";
import isUrl from "is-url";
import imageExtensions from "image-extensions";
import { BlockElement } from "generated/graphql";

export type EmptyText = {
  text: string;
};

export type ImageElement = {
  type: BlockElement.Image;
  url: string;
  children: EmptyText[];
};

export const insertImage = (editor: ReactEditor, url: string) => {
  const text = { text: "" };
  const image: ImageElement = {
    type: BlockElement.Image,
    url,
    children: [text]
  };
  const para = { type: BlockElement.Paragraph, children: [text] };
  Transforms.insertNodes(editor, para);
  Transforms.insertNodes(editor, image);
};

export const isImageUrl = (url: string) => {
  if (!url) return false;
  if (!isUrl(url)) return false;
  const ext = new URL(url).pathname.split(".").pop();
  return imageExtensions.includes(ext as string);
};

export const withImages = (editor: ReactEditor) => {
  const { insertData, isVoid } = editor;

  editor.isVoid = element => {
    return element.type === BlockElement.Image ? true : isVoid(element);
  };

  editor.insertData = data => {
    const text = data.getData("text/plain");
    const { files } = data;

    if (files && files.length > 0) {
      //@ts-ignore
      for (const file of files) {
        const reader = new FileReader();
        const [mime] = file.type.split("/");

        if (mime === "image") {
          reader.addEventListener("load", () => {
            const url = reader.result;
            //@ts-ignore
            insertImage(editor, url);
          });

          reader.readAsDataURL(file);
        }
      }
    } else if (isImageUrl(text)) {
      insertImage(editor, text);
    } else {
      insertData(data);
    }
  };

  return editor;
};
