import React, { ReactElement, useContext } from "react";
import { Flex, Text, Link } from "rebass";

import LogoutButton from "components/LogoutButton";
import MeContext from "contexts/MeContext";

export default function Me(): ReactElement {
  const { me } = useContext(MeContext);

  return (
    <Flex flexDirection="column" flexGrow={1} justifyContent="space-between">
      <Flex mt="10px" flexDirection="column">
        <Text variant="secondary">
          {me.fullName} ({me.email})
        </Text>
        <Text mt={2} variant="secondary">
          Questions or comments? You can reach us at{" "}
          <Link href="mailto:support@functionrelief.com?subject=re%3A">
            support@functionrelief.com
          </Link>
          .
        </Text>
      </Flex>
      <Flex flexDirection="column">
        <LogoutButton />
      </Flex>
    </Flex>
  );
}
