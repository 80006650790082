import React from "react";

import { ReactComponent as Progress } from "assets/icons/progress-32.svg";

export default function ProgressButton() {
  return (
    <div className="inline">
      <Progress className="path-current fill-current text-gray-600" />
    </div>
  );
}
