import { useReducer, Dispatch } from "react";

import { UnreachableCaseError } from "lib/errors";

import { MeQuery } from "generated/graphql";

export interface State {
  me: MeQuery["me"];
}

export function getInitialState(me: MeQuery["me"]): State {
  return {
    me
  };
}

export type Action = { type: "updateMe"; me: MeQuery["me"] };

function reduce(state: State, action: Action): State {
  switch (action.type) {
    case "updateMe":
      return {
        ...state,
        me: action.me
      };

    default:
      console.error(new UnreachableCaseError(action));
      return state;
  }
}

export function useMeReducer(me: MeQuery["me"]): [State, Dispatch<Action>] {
  return useReducer(reduce, getInitialState(me));
}
