import React, { ReactElement } from "react";
import { Router } from "@reach/router";
import { ReactComponent as BooksIcon } from "assets/icons/books24.svg";
import { ReactComponent as UsersIcon } from "assets/icons/users24.svg";
import { ReactComponent as GearIcon } from "assets/icons/gear24.svg";

import SidebarNavItem from "components/Admin/SidebarNavItem";
import Sidebar from "components/Admin/Sidebar";
import ProgramsBody from "components/Admin/programs/ProgramsBody";
import ProgramBody from "components/Admin/programs/ProgramBody";
import UsersBody from "components/Admin/users/UsersBody";
import TreatmentsBody from "components/Admin/treatments/TreatmentsBody";

const navigation = [
  {
    name: "Programs",
    to: "/programs",
    icon: BooksIcon as (props: React.ComponentProps<"svg">) => ReactElement
  },
  {
    name: "Users",
    to: "/users",
    icon: UsersIcon as (props: React.ComponentProps<"svg">) => ReactElement
  },
  {
    name: "Settings",
    to: "/settings",
    icon: GearIcon as (props: React.ComponentProps<"svg">) => ReactElement
  }
];

export default function AdminPage() {
  return (
    <div className="h-screen flex bg-blue-gray-50 overflow-hidden">
      {/* tablet/desktop-only */}
      <Sidebar>
        {navigation.map(item => (
          <SidebarNavItem key={item.name} {...item} />
        ))}
      </Sidebar>
      <div className="flex-1 min-w-0 flex flex-col overflow-hidden">
        <Router primary={false}>
          <ProgramsBody path="/" />
          <ProgramsBody path="/programs" />
          <ProgramBody path="/programs/:programId" />
          <TreatmentsBody path="/programs/:programId/treatments" />
          <UsersBody path="/users" />
          <UsersBody path="/users/:userId" />
        </Router>
      </div>
    </div>
  );
}
