import React, { ErrorInfo } from "react";

import ErrorPageRaw from "pages/ErrorPageRaw";

interface Props {}

interface State {
  hasError: boolean;
}

export default class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // render fallback UI
      return <ErrorPageRaw />;
    }

    return this.props.children;
  }
}
