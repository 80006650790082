import React, { ReactElement } from "react";
import { RouteComponentProps } from "@reach/router";
import { Text } from "rebass";

import ModalLayout from "components/common/ModalLayout";
import SettingsBody from "components/SettingsBody";

export default function SettingsPage(_: RouteComponentProps): ReactElement {
  return (
    <ModalLayout
      titleElement={
        <Text variant="primary" fontWeight="bold">
          Me
        </Text>
      }
    >
      <SettingsBody />
    </ModalLayout>
  );
}
