import React, { useContext } from "react";
import { RouteComponentProps } from "@reach/router";

import NotFound from "components/common/NotFound";

import MeContext from "contexts/MeContext";

interface Props extends RouteComponentProps {
  as: React.ComponentType<RouteComponentProps>;
}

export default function AdminRoute(props: Props) {
  const { me } = useContext(MeContext);
  const { as: Comp, ...otherProps } = props;
  return me.isAdmin ? <Comp {...otherProps} /> : <NotFound showHome={true} />;
}
