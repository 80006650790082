import React from "react";
import {
  EditModulePanelFragment,
  useTemplateBaseTasksQuery
} from "generated/graphql";
import MultipleSelect from "components/MultipleSelect";
import PanelFormField, {
  PanelFormInput
} from "components/common/PanelFormField";
import useParamsProgramId from "hooks/useParamsProgramId";

interface Props {
  templateTaskTriggers: EditModulePanelFragment["templateTaskTriggers"];
  onChange: (
    value:
      | {
          label: string;
          value: string;
        }[]
      | null
  ) => void;
}

export default function TaskTriggers(props: Props) {
  const programId = useParamsProgramId();
  const { data, loading, error } = useTemplateBaseTasksQuery({
    variables: { programId }
  });

  if (loading) {
    <PanelFormField label="Task triggers">
      <PanelFormInput className="cursor-not-allowed bg-gray-200" />
    </PanelFormField>;
  }
  if (error || !data) {
    return null;
  }

  return (
    <PanelFormField label="Task triggers">
      <MultipleSelect<
        {
          label: string;
          value: string;
        }[]
      >
        value={props.templateTaskTriggers.map(t => ({
          label: t.templateBaseTask.title,
          value: t.templateBaseTask.id
        }))}
        onChange={(
          value:
            | {
                label: string;
                value: string;
              }[]
            | null
        ) => props.onChange(value)}
        // @ts-ignore
        options={data.templateBaseTasks.map(t => ({
          label: t.title,
          value: t.id
        }))}
      />
    </PanelFormField>
  );
}
