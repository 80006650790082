import React, { MouseEvent } from "react";
import { useSlate } from "slate-react";
import { Editor, Transforms, Element as SlateElement } from "slate";
import { ReactComponent as BoldIcon } from "assets/icons/bold.svg";
import { ReactComponent as ItalicIcon } from "assets/icons/italic.svg";
import { ReactComponent as UnderlineIcon } from "assets/icons/underline.svg";
import { ReactComponent as HeadingOneIcon } from "assets/icons/heading-one.svg";
import { ReactComponent as HeadingTwoIcon } from "assets/icons/heading-two.svg";
import { ReactComponent as BulletedListIcon } from "assets/icons/bulleted-list.svg";
import { ReactComponent as NumberedListIcon } from "assets/icons/numbered-list.svg";
import { ReactComponent as ComponentIcon } from "assets/icons/component.svg";
import { ReactComponent as ImageIcon } from "assets/icons/image-icon.svg";
import { ReactComponent as UrlIcon } from "assets/icons/url.svg";
import {
  ComponentKey,
  insertComponent
} from "components/Editor/EditorComponent/utils";
import { insertImage, isImageUrl } from "components/Editor/ImageElement/utils";
import { BlockElement } from "generated/graphql";

const LIST_TYPES = [BlockElement.NumberedList, BlockElement.BulletedList];

export enum MarkElement {
  Bold = "bold",
  Italic = "italic",
  Underline = "underline",
  Url = "url"
}

const MarkToIcon = {
  [MarkElement.Bold]: BoldIcon,
  [MarkElement.Italic]: ItalicIcon,
  [MarkElement.Underline]: UnderlineIcon,
  [MarkElement.Url]: UrlIcon
};

const BlockToIcon = {
  [BlockElement.HeadingOne]: HeadingOneIcon,
  [BlockElement.HeadingTwo]: HeadingTwoIcon,
  [BlockElement.BulletedList]: BulletedListIcon,
  [BlockElement.NumberedList]: NumberedListIcon,
  [BlockElement.Component]: ComponentIcon,
  [BlockElement.Image]: ImageIcon,
  [BlockElement.Paragraph]: HeadingOneIcon,
  [BlockElement.ListItem]: HeadingOneIcon
};

export const toggleBlock = (editor: Editor, format: BlockElement) => {
  const isActive = isBlockActive(editor, format);
  const isList = LIST_TYPES.includes(format);

  Transforms.unwrapNodes(editor, {
    match: n =>
      LIST_TYPES.includes(
        // @ts-ignore
        !Editor.isEditor(n) && SlateElement.isElement(n) && n.type
      ),
    split: true
  });
  const newProperties: Partial<SlateElement> = {
    type: isActive
      ? BlockElement.Paragraph
      : isList
      ? BlockElement.ListItem
      : format
  };
  Transforms.setNodes(editor, newProperties);

  if (!isActive && isList) {
    const block = { type: format, children: [] };
    Transforms.wrapNodes(editor, block);
  }
};

export const toggleMark = (editor: Editor, format: MarkElement) => {
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};

const isBlockActive = (editor: Editor, format: BlockElement) => {
  // @ts-ignore
  const [match] = Editor.nodes(editor, {
    match: n => {
      return (
        !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === format
      );
    }
  });

  return !!match;
};

const isMarkActive = (editor: Editor, format: MarkElement) => {
  const marks = Editor.marks(editor);
  return !!marks ? !!marks[format] : false;
};

interface BlockButtonProps {
  formatType: BlockElement;
}

export const BlockButton = (props: BlockButtonProps) => {
  const editor = useSlate();
  const Icon = BlockToIcon[props.formatType];
  const active = isBlockActive(editor, props.formatType);
  return (
    <div
      className="p-2 rounded bg-gray-100 mx-1"
      onMouseDown={(event: MouseEvent<HTMLDivElement>) => {
        event.preventDefault();
        toggleBlock(editor, props.formatType);
      }}
    >
      <Icon
        className={`ponter path-current fill-current text-${
          active ? "gray-900" : "gray-300"
        }`}
      />
    </div>
  );
};

export const InsertComponentButton = () => {
  const formatType = BlockElement.Component;
  const editor = useSlate();
  const Icon = BlockToIcon[formatType];
  const active = isBlockActive(editor, formatType);
  return (
    <div
      className="p-2 rounded bg-gray-100 mx-1"
      onMouseDown={(event: MouseEvent<HTMLDivElement>) => {
        event.preventDefault();
        insertComponent(editor, ComponentKey.FavoriteButton);
      }}
    >
      <Icon
        className={`ponter path-current fill-current text-${
          active ? "gray-900" : "gray-300"
        }`}
      />
    </div>
  );
};

export const InsertImageButton = () => {
  const formatType = BlockElement.Image;
  const editor = useSlate();
  const Icon = BlockToIcon[formatType];
  const active = isBlockActive(editor, formatType);
  return (
    <div
      className="p-2 rounded bg-gray-100 mx-1"
      onMouseDown={(event: MouseEvent<HTMLDivElement>) => {
        event.preventDefault();
        const url = window.prompt("Enter the URL of the image:");
        if (url && !isImageUrl(url)) {
          alert("URL is not an image");
          return;
        }
        insertImage(editor, url as string);
      }}
    >
      <Icon
        className={`ponter path-current fill-current text-${
          active ? "gray-900" : "gray-300"
        }`}
      />
    </div>
  );
};

export const toggleUrl = (editor: Editor) => {
  const isActive = isMarkActive(editor, MarkElement.Url);

  if (isActive) {
    Editor.removeMark(editor, MarkElement.Url);
  } else {
    const url = window.prompt("Enter the URL of the image:");
    if (!url || !(url.startsWith("http:") || url.startsWith("https:"))) {
      alert("URL must start with http: or https:");
      return;
    }
    Editor.addMark(editor, MarkElement.Url, url.trim());
  }
};

export const InsertUrlButton = () => {
  const formatType = MarkElement.Url;
  const editor = useSlate();
  const Icon = MarkToIcon[formatType];
  const active = isMarkActive(editor, formatType);
  return (
    <div
      className="p-2 rounded bg-gray-100 mx-1"
      onMouseDown={(event: MouseEvent<HTMLDivElement>) => {
        event.preventDefault();
        toggleUrl(editor);
      }}
    >
      <Icon
        className={`ponter path-current fill-current text-${
          active ? "gray-900" : "gray-300"
        }`}
      />
    </div>
  );
};

interface MarkButtonProps {
  formatType: MarkElement;
}

export const MarkButton = (props: MarkButtonProps) => {
  const editor = useSlate();
  const Icon = MarkToIcon[props.formatType];
  const active = isMarkActive(editor, props.formatType);
  return (
    <div
      className="p-2 rounded bg-gray-100 mx-1"
      onMouseDown={(event: MouseEvent<HTMLDivElement>) => {
        event.preventDefault();
        toggleMark(editor, props.formatType);
      }}
    >
      <Icon
        className={`ponter path-current fill-current text-${
          active ? "gray-900" : "gray-300"
        }`}
      />
    </div>
  );
};
