import { useReducer } from "react";

import { Slide, SlideType } from "generated/graphql";
import { State, Action, FormSlide } from "./types";
import {
  deserializeFormSlides,
  getIsLast,
  getIsFirst,
  updateItemInArray,
  swapItemInArray,
  validateDocument,
  getCurrentSlide
} from "./utils";
import { getBlankDocument, getBlankSlide } from "lib/modules";

export function getInitialState(slides: Slide[]): State {
  const initSlides = slides.length === 0 ? [getBlankDocument()] : slides;
  const deserialized = deserializeFormSlides(initSlides);
  return {
    index: 0,
    initSlides,
    formSlides: deserialized
  };
}

function reduce(state: State, action: Action) {
  const isLast = getIsLast(state);
  const isFirst = getIsFirst(state);
  const currentSlide = getCurrentSlide(state);
  switch (action.type) {
    case "next":
      if (isLast) {
        return state;
      }

      return { ...state, index: state.index + 1 };

    case "previous":
      if (isFirst) {
        return state;
      }

      return { ...state, index: state.index - 1 };

    case "addSlideBefore":
      const addableBefore = [...state.formSlides];
      addableBefore.splice(state.index, 0, getBlankSlide(SlideType.Document));
      return {
        ...state,
        formSlides: addableBefore
      };

    case "addSlideAfter":
      const addableAfter = [...state.formSlides];
      addableAfter.splice(
        state.index + 1,
        0,
        getBlankSlide(SlideType.Document)
      );
      return {
        ...state,
        index: state.index + 1,
        formSlides: addableAfter
      };

    case "changeSlideType":
      return {
        ...state,
        formSlides: swapItemInArray(
          state.formSlides,
          getBlankSlide(action.slideType),
          state.index
        )
      };

    case "deleteSlide":
      const deletableSlides = [...state.formSlides];
      deletableSlides.splice(state.index, 1);

      if (state.formSlides.length === 1) {
        return state;
      }

      return {
        ...state,
        index: isLast ? state.index - 1 : state.index,
        formSlides: deletableSlides
      };

    case "updateModuleTaskGroup":
      const newTaskGroup = {
        ...currentSlide,
        content: action.value
      };

      return {
        ...state,
        formSlides: updateItemInArray<FormSlide>(
          state.formSlides,
          newTaskGroup,
          state.index
        )
      };

    case "updateModuleDocument":
      if (!validateDocument(currentSlide)) {
        return state;
      }

      const newDocument: FormSlide = {
        ...currentSlide,
        content: action.value
      };

      return {
        ...state,
        formSlides: updateItemInArray(
          state.formSlides,
          newDocument,
          state.index
        )
      };

    case "updateModuleTakeaway":
      const newTakeaway = {
        ...currentSlide,
        content: action.value
      };

      return {
        ...state,
        formSlides: updateItemInArray<FormSlide>(
          state.formSlides,
          newTakeaway,
          state.index
        )
      };

    default:
      console.error(`error processesing action of type ${action}`);
      return state;
  }
}

export function useCarouselReducer(slides: Slide[]) {
  return useReducer(reduce, getInitialState(slides));
}
