import React from "react";

import PanelWrapper from "components/common/PanelWrapper";
import PanelHeader from "components/common/PanelHeader";
import PanelLoading from "components/common/PanelLoading";

import Container from "components/EditModulePanel/Container";

interface Props {
  moduleId?: string;
  onClose: () => void;
}

export default function EditModulePanel(props: Props) {
  const { moduleId, onClose } = props;

  if (!moduleId) {
    return <PanelLoading />;
  }

  return (
    <PanelWrapper
      header={
        <PanelHeader
          title="Edit module"
          subtitle="Update the information below and click save to edit this module"
        />
      }
      isOpen={!!moduleId}
      onClose={onClose}
    >
      <Container onClose={onClose} moduleId={moduleId} />
    </PanelWrapper>
  );
}
