import React, { ReactElement } from "react";
import { Link, useMatch } from "@reach/router";
import classNames from "lib/classNames";

export type NavItem = {
  name: string;
  to: string;
  icon: (props: React.ComponentProps<"svg">) => ReactElement;
};

interface Props extends NavItem {}

export default function SidebarNavItem(props: Props) {
  const match = useMatch(`${props.to}/*`);
  return (
    <Link
      to={props.to}
      className={classNames(
        "flex items-center p-4 rounded-lg text-green-200 hover:bg-green-800",
        match ? "bg-green-800" : ""
      )}
    >
      <props.icon className="h-6 w-6" />
      <span className="sr-only">{props.name}</span>
    </Link>
  );
}
