import React, { ErrorInfo } from "react";

import client from "lib/apollo";
import { ReportErrorDocument } from "generated/graphql";
import ErrorPageRaw from "pages/ErrorPageRaw";

interface Props {}

interface State {
  hasError: boolean;
}

export default class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    client.mutate({
      mutation: ReportErrorDocument,
      variables: {
        payload: {
          componentName: "ErrorBoundary",
          errorMessage: error.message,
          stack: error.stack
        }
      }
    });
    console.error(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // render fallback UI
      return <ErrorPageRaw />;
    }

    return this.props.children;
  }
}
