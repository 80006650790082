import React, { ReactNode } from "react";

import Logo from "components/Admin/Logo";

interface Props {
  children: ReactNode;
}

export default function Sidebar(props: Props) {
  return (
    <div className="flex lg:flex-shrink-0">
      <div className="flex flex-col w-20">
        <div className="flex flex-col h-0 flex-1 overflow-y-auto bg-green-700">
          <div className="flex-1 flex flex-col">
            <div className="flex-shrink-0 bg-green-800 py-2 flex items-center justify-center">
              <Logo />
            </div>
            <nav
              aria-label="Sidebar"
              className="py-6 flex flex-col items-center space-y-3"
            >
              {props.children}
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
}
