import React, { ReactElement, useEffect } from "react";
import { Link, useMatch } from "@reach/router";
import { Box, Text, Flex, Button, Heading } from "rebass";

import LogoutButton, { Variant } from "./LogoutButton";
import { ReactComponent as DeliverySVG } from "../assets/icons/delivery-fast.svg";
import { useReportErrorMutation } from "generated/graphql";

interface Props {
  error?: Error;
  componentName: string;
}

export default function Error(props: Props): ReactElement {
  const match = useMatch("/");
  const [mutation, { error: reportingError }] = useReportErrorMutation({
    variables: {
      payload: {
        componentName: props.componentName,
        errorMessage:
          props.error?.message || `undefined error in ${props.componentName}`,
        stack: props.error?.stack
      }
    }
  });

  useEffect(() => {
    mutation();
  }, [props.componentName, props.error, mutation]);

  if (!!reportingError) {
    console.error("error reporting error", reportingError);
  }

  return (
    <Flex
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      minHeight="250px"
    >
      <Heading textAlign="center" py={2}>
        Something went wrong
      </Heading>
      <Text variant="secondary">We're working on it!</Text>
      <Box m="20px">
        <DeliverySVG
          filter="invert(69%) sepia(18%) saturate(0%) hue-rotate(256deg) brightness(97%)
        contrast(89%)"
          title="error"
        />
      </Box>
      {!match && (
        <Link to="/">
          <Button sx={{ cursor: "pointer" }}>head home</Button>
        </Link>
      )}
      <Box mt={2}>
        <LogoutButton sx={{ width: 104 }} variant={Variant.Gray} />
      </Box>
    </Flex>
  );
}
