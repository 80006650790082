import React, { ReactNode } from "react";

interface Props {
  children: ReactNode;
}

export default function AdminTitle(props: Props) {
  return (
    <div className="bg-white flex-shrink-0 h-16 px-6 border-b border-blue-gray-200 flex items-center">
      <p className="flex text-lg font-medium text-blue-gray-900">
        {props.children}
      </p>
    </div>
  );
}
