import { BlockElement, ModuleType, SlideType, Slide } from "generated/graphql";
import { FormSlide } from "components/SlidesCarousel/types";

export const ModuleTypeToLabel = {
  [ModuleType.Level_1]: "summary",
  [ModuleType.Level_2]: "summary",
  [ModuleType.Level_3]: "summary",
  [ModuleType.Level_4]: "summary",
  [ModuleType.Level_5]: "summary",
  [ModuleType.Level_6]: "summary",
  [ModuleType.Summary]: "summary",
  [ModuleType.Tutorial]: "Tutorial",
  [ModuleType.Lesson]: "Lesson",
  [ModuleType.Movement]: "Movement",
  [ModuleType.Mindfulness]: "Mindfulness",
  [ModuleType.MentalHealth]: "Mindfulness",
  [ModuleType.Treatments]: "Treatments",
  [ModuleType.PainCheckIn]: "Check-in"
};

export const moduleTypes = [
  ModuleType.Level_1,
  ModuleType.Level_2,
  ModuleType.Level_3,
  ModuleType.Level_4,
  ModuleType.Level_5,
  ModuleType.Level_6,
  ModuleType.Summary,
  ModuleType.Tutorial,
  ModuleType.Lesson,
  ModuleType.Movement,
  ModuleType.Mindfulness,
  ModuleType.MentalHealth,
  ModuleType.Treatments,
  ModuleType.PainCheckIn
];

export function getBlankDocument(): Slide {
  return {
    id: "NEW",
    type: SlideType.Document,
    content: JSON.stringify([
      {
        type: BlockElement.Paragraph,
        children: [{ italic: false, bold: false, underline: false, text: "" }]
      }
    ])
  };
}

export function getBlankFormDocument(): FormSlide {
  return {
    id: "NEW",
    type: SlideType.Document,
    content: [
      {
        type: BlockElement.Paragraph,
        children: [{ italic: false, bold: false, underline: false, text: "" }]
      }
    ]
  };
}

export function getBlankComponent(): FormSlide {
  return {
    id: "NEW",
    type: SlideType.Component
  };
}

export function getBlankShouldRemind(): FormSlide {
  return {
    id: "NEW",
    type: SlideType.ShouldRemind
  };
}

export function getBlankSetPlan(): FormSlide {
  return {
    id: "NEW",
    type: SlideType.SetPlan
  };
}

export function getBlankChooseAvatar(): FormSlide {
  return {
    id: "NEW",
    type: SlideType.ChooseAvatar
  };
}

export function getBlankActivity(): FormSlide {
  return {
    id: "NEW",
    type: SlideType.Activity
  };
}

export function getBlankTakeaway(): FormSlide {
  return {
    id: "NEW",
    type: SlideType.Takeaway,
    content: "Example Takeaway (editing wip)"
  };
}

export function getBlankRecordPain(): FormSlide {
  return {
    id: "NEW",
    type: SlideType.RecordPain
  };
}

export function getBlankSlide(type: SlideType): FormSlide {
  switch (type) {
    case SlideType.Document:
      return getBlankFormDocument();
    case SlideType.Activity:
      return getBlankActivity();
    case SlideType.Component:
      return getBlankComponent();
    case SlideType.Takeaway:
      return getBlankTakeaway();
    case SlideType.SetPlan:
      return getBlankSetPlan();
    case SlideType.ShouldRemind:
      return getBlankShouldRemind();
    case SlideType.ChooseAvatar:
      return getBlankChooseAvatar();
    case SlideType.RecordPain:
      return getBlankRecordPain();
    default:
      return getBlankFormDocument();
  }
}
