import React, { ReactNode } from "react";

interface Props {
  header: ReactNode;
  children: ReactNode;
}

export default function SidebarSubNav(props: Props) {
  return (
    <nav
      aria-label="Sections"
      className="flex flex-col flex-shrink-0 w-96 bg-white border-r border-blue-gray-200"
    >
      {props.header}
      <div className="flex-1 min-h-0 overflow-y-auto">{props.children}</div>
    </nav>
  );
}
