import React from "react";

import {
  MarkButton,
  BlockButton,
  MarkElement,
  InsertUrlButton,
  InsertComponentButton,
  InsertImageButton
} from "components/Editor/EditorMenuButton";
import SlidesNav from "components/SlidesCarousel/SlidesNav";
import SlidesMenu from "components/SlidesCarousel/SlidesMenu";
import { BlockElement } from "generated/graphql";

export default function EditorMenu() {
  return (
    <div className="flex flex-col w-full items-start">
      <div className="flex justify-between  w-full">
        <div className="flex items-center">
          <MarkButton formatType={MarkElement.Bold} />
          <MarkButton formatType={MarkElement.Italic} />
          <MarkButton formatType={MarkElement.Underline} />
          <BlockButton formatType={BlockElement.HeadingOne} />
          <BlockButton formatType={BlockElement.HeadingTwo} />
          <BlockButton formatType={BlockElement.NumberedList} />
          <BlockButton formatType={BlockElement.BulletedList} />
          <InsertUrlButton />
          <InsertComponentButton />
          <InsertImageButton />
        </div>
        <SlidesNav />
      </div>
      <SlidesMenu />
    </div>
  );
}
