import React, { ReactElement, useEffect } from "react";
import { useLocation, RouteComponentProps } from "@reach/router";

import { useReportFrontend404Mutation } from "generated/graphql";

import NotFound from "components/common/NotFound";

export default function NotFoundPage(_: RouteComponentProps): ReactElement {
  const { pathname } = useLocation();
  const [mutation, { error: reportingError }] = useReportFrontend404Mutation({
    variables: {
      payload: {
        pathname
      }
    }
  });

  useEffect(() => {
    mutation();
  }, [pathname, mutation]);

  if (!!reportingError) {
    console.error("error reporting error", reportingError);
  }

  return <NotFound showHome={true} />;
}
