import React, { Fragment, MouseEvent } from "react";
import { useSlate } from "slate-react";
import { Menu, Transition } from "@headlessui/react";
import { DotsVerticalIcon } from "@heroicons/react/solid";
import {
  ComponentKey,
  replaceComponent
} from "components/Editor/EditorComponent/utils";

function classNames(classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

const ITEMS = [
  {
    key: ComponentKey.FavoriteButton,
    label: "Favorite button"
  },
  {
    key: ComponentKey.ProgressButton,
    label: "Progress button"
  },
  {
    key: ComponentKey.MoreButton,
    label: "More button"
  }
];

export default function ComponentMenu() {
  const editor = useSlate();
  return (
    <Menu as="div" className="relative inline-block text-left px-2">
      {({ open }) => (
        <>
          <div>
            <Menu.Button className="bg-white rounded-full flex items-center text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
              <span className="sr-only">Open options</span>
              <DotsVerticalIcon className="h-5 w-5" aria-hidden="true" />
            </Menu.Button>
          </div>

          <Transition
            show={open}
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items
              static
              className="origin-top-right absolute left-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
            >
              <div className="py-1">
                {ITEMS.map(item => (
                  <Menu.Item>
                    {({ active }) => (
                      <div
                        className={classNames([
                          active
                            ? "bg-gray-100 text-gray-900"
                            : "text-gray-700",
                          "block px-4 py-2 text-sm"
                        ])}
                        onMouseDown={(e: MouseEvent<HTMLDivElement>) => {
                          e.preventDefault();
                          replaceComponent(editor, item.key);
                        }}
                      >
                        {item.label}
                      </div>
                    )}
                  </Menu.Item>
                ))}
              </div>
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
}
