import React, { useContext } from "react";
import { SlideType } from "generated/graphql";
import Select from "components/Select";

import SlidesCarouselContext from "components/SlidesCarousel/Context";

import {
  SlideTypeToLabel,
  moduleSlideTypes
} from "components/SlidesCarousel/utils";

export default function SlideTypeSelect() {
  const { dispatch, index, formSlides } = useContext(SlidesCarouselContext);
  const currentType = formSlides[index].type;
  return (
    <Select
      value={currentType}
      onChange={e => {
        dispatch({
          type: "changeSlideType",
          slideType: e.target.value as SlideType
        });
      }}
      // @ts-ignore
      options={moduleSlideTypes.map(t => ({
        value: t,
        label: SlideTypeToLabel[t]
      }))}
    />
  );
}
