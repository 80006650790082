import React, { ReactElement } from "react";
import { RouteComponentProps } from "@reach/router";

import NotFound from "components/common/NotFound";

export default function UnauthedNotFoundPage(
  _: RouteComponentProps
): ReactElement {
  return <NotFound showHome={false} />;
}
