import { useContext } from "react";
import { Slide, ModuleSlideInput, SlideType } from "generated/graphql";
import { FormSlide, State } from "./types";

import CarouselContext from "./Context";

export function getCurrentSlide(state: State): FormSlide {
  return state.formSlides[state.index];
}

export function useCarouselSlide() {
  const state = useContext(CarouselContext);

  return getCurrentSlide(state);
}

function deserializeSlideContent(slide: Slide) {
  return { ...slide, content: JSON.parse(slide.content || "") };
}

function serializeSlideContent(slide: FormSlide): Slide {
  return { ...slide, content: JSON.stringify(slide.content) };
}

export function deserializeFormSlides(slides: Slide[]): FormSlide[] {
  return slides.map(slide => {
    switch (slide.type) {
      case SlideType.Activity:
      case SlideType.Component:
      case SlideType.SetPlan:
      case SlideType.ShouldRemind:
      case SlideType.RecordPain:
      case SlideType.ChooseAvatar:
        return slide;
      case SlideType.Takeaway:
      case SlideType.Document:
        return deserializeSlideContent(slide as Slide);
      default:
        return slide;
    }
  });
}

export function serializeFormSlides(slides: FormSlide[]): Slide[] {
  return slides.map(slide => {
    switch (slide.type) {
      case SlideType.Activity:
      case SlideType.Component:
      case SlideType.SetPlan:
      case SlideType.RecordPain:
      case SlideType.ChooseAvatar:
        return slide as Slide;
      case SlideType.Takeaway:
      case SlideType.Document:
        return serializeSlideContent(slide as FormSlide);
      default:
        return slide as Slide;
    }
  });
}

export function cleanSlides(slides: Slide[]): ModuleSlideInput[] {
  return slides.map(s => ({
    id: s.id,
    type: s.type,
    // @ts-ignore
    children: !!s.children ? s.children : undefined,
    // @ts-ignore
    text: !!s.text ? s.text : undefined,
    content: s.content
    // @ts-ignore
  }));
}

export const getIsLast = (state: State) =>
  state.formSlides.length === state.index + 1;
export const getIsFirst = (state: State) => state.index === 0;

export function updateItemInArray<T>(array: T[], newItem: T, idx: number) {
  return array.map((item, index) => {
    if (index !== idx) {
      return item;
    }

    return {
      ...item,
      ...newItem
    };
  });
}

export function swapItemInArray<T>(array: T[], newItem: T, idx: number) {
  return array.map((item, index) => {
    if (index !== idx) {
      return item;
    }

    return newItem;
  });
}

export function validateDocument(slide: FormSlide): slide is FormSlide {
  return slide.type === SlideType.Document;
}

export const SlideTypeToLabel = {
  [SlideType.Document]: "Document",
  [SlideType.Activity]: "Activity",
  [SlideType.Component]: "Component",
  [SlideType.Takeaway]: "Takeaway",
  [SlideType.SetPlan]: "Set plan",
  [SlideType.ShouldRemind]: "Should remind",
  [SlideType.ChooseAvatar]: "Choose avatar",
  [SlideType.RecordPain]: "Record pain"
};

export const moduleSlideTypes = [
  SlideType.Activity,
  SlideType.Component,
  SlideType.Document,
  SlideType.Takeaway,
  SlideType.SetPlan,
  SlideType.ShouldRemind,
  SlideType.ChooseAvatar,
  SlideType.RecordPain
];
