import React, { ReactNode } from "react";

interface Props {
  children: ReactNode;
}

const PanelFooter = (props: Props) => {
  return (
    <div className="bg-white flex-shrink-0 px-4 border-t border-gray-200 py-5 sm:px-6 fixed bottom-0 w-screen max-w-2xl">
      <div className="space-x-3 flex justify-end">{props.children}</div>
    </div>
  );
};

export default PanelFooter;
