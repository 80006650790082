import React, { ReactElement } from "react";
import { Link } from "@reach/router";
import { Box, Text, Button, Heading, Flex } from "rebass";

import ModalLayout from "components/common/ModalLayout";

interface Props {
  showHome: boolean;
}

export default function NotFound(props: Props): ReactElement {
  return (
    <ModalLayout
      titleElement={
        <Text fontWeight="bold" pl="3px" fontSize={1}>
          404
        </Text>
      }
    >
      <Flex
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        minHeight="250px"
        mt={60}
      >
        <Heading textAlign="center" py={2}>
          Not found
        </Heading>
        <Text variant="secondary">Sorry, we don't recognize this route</Text>
        <Box m="20px">
          <Text fontSize={4} mb={3} mt={1}>
            <span role="img" aria-label="muscle">
              ¯\_(ツ)_/¯
            </span>
          </Text>
        </Box>
        {!!props.showHome && (
          <Link to="/">
            <Button sx={{ cursor: "pointer" }}>back home</Button>
          </Link>
        )}
      </Flex>
    </ModalLayout>
  );
}
