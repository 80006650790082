import React, { ReactNode } from "react";
import { EditModulePanelFragment } from "generated/graphql";
import PanelFormField, {
  PanelButton,
  ButtonVariant
} from "components/common/PanelFormField";

interface Props {
  templateScheduleTaskTriggers: EditModulePanelFragment["templateScheduleTaskTriggers"];
  templateUnscheduleTaskTriggers: EditModulePanelFragment["templateUnscheduleTaskTriggers"];
}

interface ItemProps {
  children: ReactNode;
  href: string;
}

const TriggerItem = ({ href, children }: ItemProps) => {
  return (
    <div className="flex flex-row items-center mb-3">
      <div className="mr-4">{children}</div>
      <a href={href} target="_blank" rel="noreferrer">
        <PanelButton label="Edit" variant={ButtonVariant.Gray} />
      </a>
    </div>
  );
};

const getScheduleTriggerDesc = (
  scheduleTrigger: EditModulePanelFragment["templateScheduleTaskTriggers"][0]
) => {
  const taskTitle = scheduleTrigger.templateBaseTask.title;
  const offsetDesc = "offset: " + scheduleTrigger.startOffset;
  const rrules = scheduleTrigger.recurrences.rrules.map(
    rrule => rrule.description
  );
  return `${taskTitle} (${[...rrules, offsetDesc].join(", ")})`;
};

const getUnscheduleTriggerDesc = (
  unscheduleTrigger: EditModulePanelFragment["templateUnscheduleTaskTriggers"][0]
) => {
  const taskTitle = unscheduleTrigger.templateBaseTask.title;
  const offsetDesc = "offset: " + unscheduleTrigger.endOffset;
  return `${taskTitle} (${offsetDesc})`;
};

const ScheduleTaskTriggers = ({ templateScheduleTaskTriggers }: Props) => {
  return (
    <PanelFormField label="Schedule task triggers">
      <div className="flex flex-col">
        {templateScheduleTaskTriggers.map(scheduleTrigger => {
          return (
            <TriggerItem
              href={`${process.env.REACT_APP_FUNCTION_API_URL}/admin/tasks/templatescheduletasktrigger/${scheduleTrigger.id}/change/`}
              key={scheduleTrigger.templateBaseTask.id}
            >
              {getScheduleTriggerDesc(scheduleTrigger)}
            </TriggerItem>
          );
        })}
        <a
          href={`${process.env.REACT_APP_FUNCTION_API_URL}/admin/tasks/templatescheduletasktrigger/add/`}
          target="_blank"
          rel="noreferrer"
        >
          <PanelButton label="Add" variant={ButtonVariant.Green} />
        </a>
      </div>
    </PanelFormField>
  );
};

const UnscheduleTaskTriggers = ({ templateUnscheduleTaskTriggers }: Props) => {
  return (
    <PanelFormField label="Unschedule task triggers">
      <div className="flex flex-col">
        {templateUnscheduleTaskTriggers.map(unscheduleTrigger => {
          return (
            <TriggerItem
              href={`${process.env.REACT_APP_FUNCTION_API_URL}/admin/tasks/templateunscheduletasktrigger/${unscheduleTrigger.id}/change/`}
              key={unscheduleTrigger.templateBaseTask.id}
            >
              {getUnscheduleTriggerDesc(unscheduleTrigger)}
            </TriggerItem>
          );
        })}
        <a
          href={`${process.env.REACT_APP_FUNCTION_API_URL}/admin/tasks/templateunscheduletasktrigger/add/`}
          target="_blank"
          rel="noreferrer"
        >
          <PanelButton label="Add" variant={ButtonVariant.Green} />
        </a>
      </div>
    </PanelFormField>
  );
};

const TaskScheduleTriggers = (props: Props) => {
  return (
    <>
      <ScheduleTaskTriggers {...props} />
      <UnscheduleTaskTriggers {...props} />
    </>
  );
};

export default TaskScheduleTriggers;
