import React from "react";

import { Dialog } from "@headlessui/react";

interface Props {
  title: string;
  subtitle?: string;
}

export default function PanelHeader(props: Props) {
  const { title, subtitle } = props;

  return (
    <div className="space-y-1">
      <Dialog.Title className="text-lg font-medium text-gray-900">
        {title}
      </Dialog.Title>
      <p className="text-sm text-gray-500">{subtitle}</p>
    </div>
  );
}
