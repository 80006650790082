import React, { Fragment, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";

import { CarouselProvider } from "components/SlidesCarousel/Context";
import SlidesCarousel from "components/SlidesCarousel";

import { EditSlidesModalFragment, Slide } from "generated/graphql";
import EditSlidesButton from "components/EditSlidesButton";

interface Props {
  module: EditSlidesModalFragment;
  isOpen: boolean;
  onClose: () => void;
}

export default function EditSlidesModal(props: Props) {
  const { module, onClose, isOpen } = props;

  const cancelButtonRef = useRef<HTMLButtonElement>(null);

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-10 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        open={isOpen}
        onClose={() => {}}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          {!!module && (
            <CarouselProvider slides={module.slides as Slide[]}>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all md:max-w-xl sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                  <div className="bg-white px-10 pt-5 pb-4 sm:p-6 sm:pb-4">
                    <SlidesCarousel />
                  </div>
                  <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                    <EditSlidesButton moduleId={module.id} onClose={onClose} />
                    <button
                      type="button"
                      className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                      onClick={onClose}
                      ref={cancelButtonRef}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </Transition.Child>
            </CarouselProvider>
          )}
        </div>
      </Dialog>
    </Transition.Root>
  );
}
