import React from "react";
import Select, { Props as SelectProps } from "react-select";

interface Props<T> extends SelectProps<T> {}

function MultipleSelect<T>(props: Props<T>) {
  return (
    <Select
      defaultValue={props.defaultValue}
      isMulti
      name={props.name}
      value={props.value}
      //@ts-ignore
      onChange={props.onChange}
      options={props.options}
      className="block w-full pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm rounded-md"
    />
  );
}

export default MultipleSelect;
