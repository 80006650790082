import React, { useState } from "react";
import { Link } from "@reach/router";
import { ChevronRightIcon } from "@heroicons/react/solid";
import { SeriesAttrsRecursiveFragment } from "generated/graphql";
import { navigate } from "@reach/router";

import SidebarSubNav from "components/Admin/programs/SidebarSubNav";
import AdminTitle from "components/Admin/AdminTitle";
import ProgramSeries from "components/Admin/programs/ProgramSeries";
import SidebarSubNavItem from "components/Admin/programs/SidebarSubNavItem";
import useParamsProgramId from "hooks/useParamsProgramId";

interface Props {
  title: string;
  programSeries: { series: SeriesAttrsRecursiveFragment }[];
}

export default function ProgramBodyContent(props: Props) {
  const programId = useParamsProgramId();
  const [activeSeries, setActiveSeries] = useState(
    props.programSeries[0].series
  );

  return (
    <>
      <SidebarSubNav
        header={
          <AdminTitle>
            <>
              <Link
                className="text-gray-500 hover:text-gray-900 cursor-pointer"
                to="/programs"
              >
                Programs
              </Link>
              <ChevronRightIcon
                className="mt-1 mx-1 flex-shrink-0 h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
              {props.title}
            </>
          </AdminTitle>
        }
      >
        {props.programSeries.map(item => (
          <SidebarSubNavItem
            key={item.series.id}
            isActive={item.series.id === activeSeries.id}
            onClick={() => setActiveSeries(item.series)}
            {...item.series}
          />
        ))}
        <SidebarSubNavItem
          key="treatments"
          isActive={false}
          title="Treatments"
          onClick={() => navigate(`/programs/${programId}/treatments`)}
        />
      </SidebarSubNav>
      <ProgramSeries series={activeSeries} />
    </>
  );
}
