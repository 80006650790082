import React from "react";
import { RouteComponentProps } from "@reach/router";

import Error from "components/Error";
import Loader from "components/common/Loader";
import { useGetProgramQuery } from "generated/graphql";

import ProgramBodyContent from "components/Admin/programs/ProgramBodyContent";

interface Props extends RouteComponentProps {
  programId?: string;
}

export default function ProgramBody(props: Props) {
  const { programId } = props;
  const { data, loading, error } = useGetProgramQuery({
    variables: {
      programId: programId || "no program id found"
    }
  });

  if (loading) {
    return (
      <div className="mt-12">
        <Loader color="#000" size={54} />
      </div>
    );
  }

  if (error || data === undefined || !data.getProgram.program) {
    return <Error componentName="ProgramBody" error={error} />;
  }

  const title = data.getProgram.program.title;
  const programSeries = [...data.getProgram.program.programSeries];

  return (
    <main className="flex-1 flex overflow-hidden">
      <div className="flex-1 flex flex-col overflow-y-auto overflow-hidden">
        <div className="flex-1 flex overflow-hidden">
          {programSeries.length === 0 ? (
            <div>this program doesn't have any series</div>
          ) : (
            <ProgramBodyContent title={title} programSeries={programSeries} />
          )}
        </div>
      </div>
    </main>
  );
}
