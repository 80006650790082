import React, { useContext } from "react";
import EditorMenu from "components/Editor/EditorMenu";
import Editor from "components/Editor";
import { DocumentContent } from "../types";

import CarouselContext from "../Context";

interface Props {
  content: DocumentContent;
}

export default function ModuleDocument(props: Props) {
  const { dispatch } = useContext(CarouselContext);

  return (
    <Editor
      value={props.content}
      onChange={value => dispatch({ type: "updateModuleDocument", value })}
      header={
        <div className="flex justify-between">
          <EditorMenu />
        </div>
      }
      editorClassName="py-8 h-full p-4 border-2 bg-white rounded-3xl"
    />
  );
}
