import { BlockElement } from "generated/graphql";
import { Transforms, Text } from "slate";
import { ReactEditor } from "slate-react";

export enum ComponentKey {
  ProgressButton = "progress-button",
  FavoriteButton = "favorite-button",
  MoreButton = "more-button"
}

type ComponentElement = {
  type: BlockElement.Component;
  children: Text[];
  componentKey: ComponentKey;
};

export const withComponents = (editor: ReactEditor) => {
  const { isVoid } = editor;

  editor.isVoid = element => {
    return element.type === "component" ? true : isVoid(element);
  };

  return editor;
};

export const insertComponent = (
  editor: ReactEditor,
  componentKey: ComponentKey
) => {
  const text = { text: "" };
  const componentNode: ComponentElement = {
    type: BlockElement.Component,
    componentKey,
    children: [text]
  };
  Transforms.insertNodes(editor, componentNode);
};

export const replaceComponent = (
  editor: ReactEditor,
  componentKey: ComponentKey
) => {
  const text = { text: "" };

  const componentNode: ComponentElement = {
    type: BlockElement.Component,
    componentKey,
    children: [text]
  };

  editor.deleteForward("block");
  editor.insertNode(componentNode);
};
