import React from "react";

import { useSelected } from "slate-react";
import ProgressButton from "./ProgressButton";
import FavoriteButton from "./FavoriteButton";
import MoreButton from "./MoreButton";
import ComponentMenu from "./ComponentMenu";
import { ComponentKey } from "./utils";

const ComponentKeyToComponent = {
  [ComponentKey.ProgressButton]: ProgressButton,
  [ComponentKey.FavoriteButton]: FavoriteButton,
  [ComponentKey.MoreButton]: MoreButton
};

// @ts-ignore
const EditorComponent = ({ attributes, children, element }) => {
  const Componenet =
    ComponentKeyToComponent[element.componentKey as ComponentKey];
  const selected = useSelected();

  return (
    <div
      className={
        selected
          ? "flex items-start border-2 border-blue-200"
          : "flex items-start border-2 border-white"
      }
      {...attributes}
    >
      <div className="flex flex-row items-center" contentEditable={false}>
        <Componenet />
        {selected && <ComponentMenu />}
      </div>
      {children}
    </div>
  );
};

export default EditorComponent;
