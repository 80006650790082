import React, { ReactElement } from "react";
import { Text } from "rebass";

import Error from "components/Error";
import ModalLayout from "components/common/ModalLayout";

interface Props {
  error?: Error;
  componentName: string;
}

export default function ErrorPage(props: Props): ReactElement {
  return (
    <ModalLayout titleElement={<Text fontWeight="bold">Error</Text>}>
      <Error {...props} />
    </ModalLayout>
  );
}
