import React from "react";

import { useGetModuleQuery } from "generated/graphql";

import Error from "components/Error";
import Loader from "components/common/Loader";
import Body from "components/EditModulePanel/Body";

interface Props {
  moduleId: string;
  onClose: () => void;
}

export default function Container(props: Props) {
  const { loading, error, data } = useGetModuleQuery({
    variables: { id: props.moduleId }
  });

  if (loading) {
    return (
      <div className="mt-6">
        <Loader color="#000" size={54} />
      </div>
    );
  }

  if (error || !data || !data.getModule.module) {
    return <Error componentName="EditModulePanelContainer" error={error} />;
  }

  return <Body onClose={props.onClose} module={data.getModule.module} />;
}
