import React from "react";
import { RouteComponentProps } from "@reach/router";

import Loader from "components/common/Loader";
import { useUsersListQuery } from "generated/graphql";
import UserRow from "components/Admin/users/UserRow";
import AdminTitle from "components/Admin/AdminTitle";

export default function UsersBody(_: RouteComponentProps) {
  const { data, loading, error } = useUsersListQuery();

  if (loading) {
    return (
      <div className="mt-6">
        <Loader color="#000" size={54} />
      </div>
    );
  }

  if (error || data === undefined) {
    return <div>Something went wrong</div>;
  }

  return (
    <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
      {/* Page title & actions */}
      <AdminTitle>Users</AdminTitle>
      <div className="align-middle inline-block min-w-full border-b border-gray-200">
        <table className="min-w-full">
          <thead>
            <tr className="border-t border-gray-200">
              <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                <span className="lg:pl-2">User</span>
              </th>
              <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Email
              </th>
              <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Programs
              </th>
              <th className="hidden md:table-cell px-6 py-3 border-b border-gray-200 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                Created
              </th>
              <th className="pr-4 py-3 border-b border-gray-200 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider" />
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-100">
            {data.users.map(item => (
              <UserRow key={item.id} {...item} />
            ))}
          </tbody>
        </table>
      </div>
    </main>
  );
}
