import React, { ReactNode, ReactElement } from "react";
import { Flex, Box } from "rebass";

interface Props {
  children: ReactNode;
  titleElement: ReactNode;
  rightElement?: ReactNode;
}

export default function ModalLayout(props: Props): ReactElement {
  const { titleElement, rightElement, children } = props;
  return (
    <>
      <Box
        bg="#fff"
        sx={{
          position: "sticky",
          top: 0,
          right: 0,
          zIndex: 1
        }}
      >
        <Flex
          height="50px"
          justifyContent="space-between"
          alignItems="center"
          mx={20}
          sx={{ borderBottom: "solid 1px #e5e5e5" }}
        >
          {titleElement}
          {rightElement}
        </Flex>
      </Box>
      <Flex
        flexDirection="column"
        minHeight="calc(100vh - 60px)"
        pb={20}
        px={20}
        sx={{ overflowX: "hidden" }}
      >
        {children}
      </Flex>
    </>
  );
}
