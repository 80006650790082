import React from "react";

import SlideTypeSelect from "components/Editor/SlideTypeSelect";
import EditorSlideMenu from "components/Editor/EditorSlideMenu";

export default function SlidesMenu() {
  return (
    <div className="flex mt-2 mb-4 gap-2">
      <SlideTypeSelect />
      <EditorSlideMenu />
    </div>
  );
}
